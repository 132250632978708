import axios from 'axios';
import store from '../store'
export default {
    store: (data) => {
        return axios.post('appointments', {
            doctor_id: data.doctor_id,
            user_id: data.user_id,
            office_id: data.office_id,
            reason_id: data.reason_id,
            date_debut: data.date_debut,
            date_fin: data.date_fin || null,
            app_id: data.app_id,
            comment: data.comment,
            pending: data.pending
        }, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    forceStore: (data) => {
        return axios.post('force-appointments', data, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    storePeriodic: (data)=> {
        return axios.post('v1/admin/appointment/store-periodic', data, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    printMultiple: (data)=> {
        return axios.post('v1/admin/appointment/print-multiple', data, {
            responseType: 'blob',
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    update: (data, appointmentId) => {
        return axios.put('v1/admin/appointment/update/'+appointmentId, data, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    setHonored: (data, appointmentId, config) => {
        return axios.put('appointments/set-honored/'+appointmentId, data, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    delete: (appointmentId) => {
        return axios.delete('appointments/'+appointmentId, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    deleteMultiple: (data) => {
        return axios.post('v1/admin/appointment/delete-multiple', data,{
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    getInitialData: () => {
        return axios.get('initial-data?app_id=' +  Vue.$config.app_id + '&app_version=2');
    },
    getDoctors: () => {
        return axios.get('doctors?app_id=' +  Vue.$config.app_id);
    },
    getOffices: (data) => {
        return axios.get('offices?app_id=' +  Vue.$config.app_id);
    },
    getReasonsWithOpeningHours: () => {
        return axios.get('v1/front/appointment_reason/get-for-etablissement?app_id=' +  Vue.$config.app_id);
    },
    getFrontAppointmentsBetweenDates: (params) => {
        return axios.get('v1/front/appointment/between-dates', {
            params: params,
        });
    },
    getAdminAppointmentsBetweenDates: (params) => {
        return axios.get('v1/admin/appointment/between-dates', {
            params: params,
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    getRepeatedAppointments: (id) => {
        return axios.get('v1/admin/appointment/get-repeated/'+id, {
            params: {app_id: Vue.$config.app_id},
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    getAdminAppointmentById:(id) => {
        return axios.get('v1/admin/appointment/get/'+id, {
            params: {app_id: Vue.$config.app_id},
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    getUserAppointments: (data) => {
        return axios.get('appointments?user_id=' + data.user_id + '&app_id=' + data.app_id);
    },
    getFirstFreeSlotDate: (data) => {
        return axios.post('appointments/find-first-free-slot-date', data)
    },
    getFirstFreeSlotDateAdmin: (data) => {
        return axios.post('v1/admin/appointment/find-first-free-slot-date', data, {
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    hasDoubleCalendarMsg:(doctor_id)=> {
        return axios.post('check-double-calendar', {
            user_id: store.getters['auth/getUser'].id,
            doctor_id: doctor_id, app_id: Vue.$config.etablissement.id
        }, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    hasDoubleCalendarMsgAdmin:(doctor_id, user_id)=> {
        return axios.post('check-double-calendar', {
            user_id: user_id,
            doctor_id: doctor_id, app_id: Vue.$config.etablissement.id
        }, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    getDoctorUser:(doctor_id, user_id) => {
        return axios.get('v1/admin/appointment/doctor-user/' + doctor_id + '/' + user_id, {
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    storeDoctorUser:(doctorId, userId, exist) => {
        return axios.post('v1/admin/appointment/store-doctor-user', {doctor_id: doctorId, user_id: userId, existing_patient: exist}, {
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    updateDoctorUser:(doctorUserId, appointmentId) => {
        return axios.put('v1/admin/appointment/update-doctor-user/' + doctorUserId, {
            app_id: Vue.$config.app_id,
            appointment_id: appointmentId
        }, {
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
}
