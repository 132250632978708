export const env = {
    app_id: 6,
    etablissement_name: 'demo',
    production_api: 'https://api.e-rdv.lu/api/',
    local_api: 'http://bms.e-rdv/api/',
    production: true,
    use_production_api: true,
    affichage_page_accueil: true,
    full_calendar_licence: '0008481003-fcs-1680780645'
}
