import authApi from '../../api/auth.js'

const
    namespaced = true,
    state = {
        is_logged_in: false,
        isLoggedAsSomeoneElse: false,
        api_token: null,
        user: null,
    },
    getters = {
        getIsLoggedIn: state => {
            return state.is_logged_in
        },
        getIsLoggedAsSomeoneElse: state => {
            return state.isLoggedAsSomeoneElse
        },
        getUser: state => {
            return state.user
        },
        getApiToken: state => {
            return state.api_token
        },
    },
    actions = {
        setUser: (context, data) => {
            context.commit('setUser', data);
        },
        logOut: (context) => {
            context.commit('logOut');
        },
        setIsLoggedAsSomeoneElse: (context, bool)=> {
            context.commit('setIsLoggedAsSomeoneElse', bool)
        },
        setIsLoggedIn: (context, loggedIn) => {
            context.commit('setIsLoggedIn', loggedIn)
        },
        setApiToken: (context, data) => {
            context.commit('setApiToken', data)
        },
        loginAs: async (context, {userId, apiToken})=> {
            try {
                let res = await authApi.getAccountDetails(userId, apiToken)
                context.commit('setUser', res.data);
                context.commit('setApiToken', apiToken);
                context.commit('setIsLoggedAsSomeoneElse', true);
                context.commit('setIsLoggedIn', true);
                localStorage.setItem('loggedAs', userId)
                Vue.$toast.success('Connexion en tant que '+ res.data.full_name+ ' réussie.')
            }catch (e) {
                context.commit('setUser', null);
                context.commit('setApiToken', null);
                context.commit('setIsLoggedAsSomeoneElse', false);
                context.commit('setIsLoggedIn', false);
                localStorage.setItem('loggedAs', null)
                Vue.$toast.error('Connexion échouée')
            }
        }
    },
    mutations = {
        setUser: (state, data) => {
            state.user = data
            localStorage.setItem('user_id', data ? data.id : null)
        },
        logOut: (state) => {
            state.user = null;
            state.api_token = null;
            localStorage.clear();
            state.is_logged_in = false;
            state.isLoggedAsSomeoneElse = false
        },
        setIsLoggedIn: (state, loggedIn) => {
            state.is_logged_in = loggedIn;
        },
        setIsLoggedAsSomeoneElse: (state, bool)=> {
            state.isLoggedAsSomeoneElse = bool
        },
        setApiToken: (state, data) => {
            state.api_token = data
            localStorage.api_token = data
        }
    };

export default {
    namespaced: namespaced,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
