<script>
import store from "@/store";
export default {
  methods: {
    $can(permissionName) {
      let user = store.getters['auth/getUser']
      return user && user.all_permissions.indexOf(permissionName) !== -1;
    },
  },
};
</script>