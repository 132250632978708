<template>
    <CHeader fixed light>
        <CToggler
                in-header
                class=" d-lg-none"
                @click="toggleSidebarMobile"
        />
        <CToggler
                in-header
                class=" d-md-down-none"
                @click="toggleSidebarDesktop"
        />
        <!--
        <CHeaderBrand class="mx-auto d-lg-none" to="/">
            <img class="img-fluid" style="max-height: 50px" src="@/assets/logo_medical_medium.png" alt="Logo BMS Medical">
        </CHeaderBrand>
        -->

        <CHeaderNav class="ml-auto">
            <CHeaderNavItem>
                <locale-switcher class="text-primary" :flag="false" />
            </CHeaderNavItem>
            <CHeaderNavItem class="mx-2 text-danger">
                <CHeaderNavLink>
                    <i class="bi bi-bell text-danger"></i>
                </CHeaderNavLink>
            </CHeaderNavItem>
            <!--
            <CHeaderNavItem class="mx-2">
                <CHeaderNavLink>
                    <transition name="fade"  mode="out-in">
                        <i v-if="!getFullscreen" class="bi bi-arrows-fullscreen" @click="onFullscreenToggle"></i>
                        <i v-else class="bi bi-fullscreen-exit" @click="onFullscreenToggle"></i>
                    </transition>
                </CHeaderNavLink>
            </CHeaderNavItem>
            -->
            <CHeaderNavItem class="d-md-down-none mx-2">
                <CHeaderNavLink>
                    <router-link to="/prise-de-rendez-vous">
                        <i class="bi bi-calendar-week-fill"></i>
                    </router-link>
                </CHeaderNavLink>
            </CHeaderNavItem>
            <CHeaderNavItem class=" mx-2">
                <CHeaderNavLink>
                    <router-link to="/">
                        <i class="bi bi-arrow-left-circle-fill"></i>
                    </router-link>
                </CHeaderNavLink>
            </CHeaderNavItem>
        </CHeaderNav>
    </CHeader>
</template>

<script>
    import Connexion from '../front/Connexion'
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../store/namespaces";
    import { EventBus, EventList } from '../../event-bus.js';
    import permissionMixin from "@/mixins/permissionMixin.vue";
    import LocaleSwitcher from "@/components/nav/LocaleSwitcher.vue";

    export default {
        name: "AdminNavBar",
        mixins: [
            permissionMixin
        ],
        components: {
            LocaleSwitcher,
            Connexion
        },
        data() {
            return {
                config: Vue.$config,
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
                getFullscreen: namespaces.layout+'getFullscreen'
            }),
        },
        methods: {
            ...mapActions({
                toggleSidebarDesktop: namespaces.layout + 'toggleSidebarDesktop',
                toggleSidebarMobile: namespaces.layout + 'toggleSidebarMobile',
                setApiToken: namespaces.auth + 'setApiToken',
                logOut: namespaces.auth+'logOut',
                logIn: namespaces.auth + 'logIn',
                setFullscreen: namespaces.layout+'setFullscreen'
            }),
            onLogout() {
                this.logOut()
                EventBus.$emit(EventList.calendar_front.refresh)
                Vue.$toast.open({
                    message: 'Vous êtes maintenant déconnecté',
                    type: 'info',
                });
                this.$router.push("Home").catch(()=>{})

            },
            onFullscreenToggle() {
                let app = document.documentElement
                if(!this.getFullscreen && app.requestFullscreen) {
                    app.requestFullscreen().then(()=>this.setFullscreen(true));

                }
                else {
                    document.exitFullscreen().then(()=>this.setFullscreen(false))
                }
            },
            onFullscreenChange() {
                let fullscreenElement = document.fullscreenElement
                if(!fullscreenElement) {
                    this.setFullscreen(false)
                }
            }
        },
        mounted() {
            //document.addEventListener('fullscreenchange', this.onFullscreenChange(), false);
        },
        beforeDestroy() {
            //document.documentElement.removeEventListener("fullscreenchange",  this.onFullscreenChange(), false);
        }
    }
</script>
<style scoped>

</style>
