export default class EPeriodicite {
    static hebdomadaire = 1
    static deux_semaines = 2
    static trois_semaines = 3
    static quatre_semaines = 4

    static getList() {
        return [
            {
                label: 'Hebdomadaire',
                id: this.hebdomadaire
            },
            {
                label: 'Toutes les deux semaines',
                id: this.deux_semaines
            },
            {
                label: 'Toutes les trois semaines',
                id: this.trois_semaines
            },
            {
                label: 'Toutes les quatre semaines',
                id: this.quatre_semaines
            },
        ]
    }
}
