import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '../store/index'
import authApi from '../api/auth'
import VueToast from 'vue-toast-notification';
Vue.use(VueToast, {
    position: 'top-right'
});

Vue.use(VueRouter)

const urlParams = new URLSearchParams(window.location.search);
const passwordReset = urlParams.get('passwordReset');
const token = urlParams.get('token');

const routes = [
    {
        path: '*',
        name: '*',
        meta: {layout: 'default'},
        component: () => import(/* webpackChunkName: "Default" */  '../views/front/Home.vue')
    },
    {
        path: '/',
        name: 'Home',
        meta: {layout: 'default'},
        component: () => import(/* webpackChunkName: "Home" */  '../views/front/Home.vue'),
        props: {
            passwordReset: passwordReset,
            token: token
        }
    },
    {
        path: '/login_as/:userId/:apiToken',
        name: 'LoginAs',
        meta: {layout: 'default'},
        component: () => import(/* webpackChunkName: "Home" */  '../views/front/Home.vue'),
        props: {
            passwordReset: passwordReset,
            token: token
        }
    },
    {
        path: '/prise-de-rendez-vous/:doctor_id?',
        name: 'Calendar',
        meta: {layout: 'default'},
        component: () => import(/* webpackChunkName: "Calendar" */  '../views/front/CalendarView.vue')
    },
    {
        path: '/mes-rendez-vous',
        name: 'Mes rendez-vous',
        meta: {layout: 'default', requiresAuth: true},
        component: () => import(/* webpackChunkName: "MyAppointments" */ '../views/front/MyAppointments.vue'),
    },
    {
        path: '/mon-compte',
        name: 'Mon compte',
        meta: {layout: 'default', requiresAuth: true},
        component: () => import(/* webpackChunkName: "MyAccount" */ '../views/front/MyAccount.vue'),
    },
    {
        path: '/inscription',
        name: 'Inscription',
        meta: {layout: 'default'},
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "Inscription" */ '../views/front/Inscription.vue'),

    },
    {
        path: '/admin/dashboard',
        name: 'AdminDashboard',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "Dashboard" */ '../views/back/Dashboard.vue'),

    },
    {
        path: '/admin/calendrier/:doctorId?',
        name: 'Calendrier',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "AdminCalendar" */ '../views/back/calendrier/Index.vue'),
    },
    {
        path: '/admin/horaires/:doctorId?',
        name: 'Horaires',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "Horaires" */ '../views/back/horaires/Index.vue'),

    },
    {
        path: '/admin/patients',
        name: 'Patients',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "Patient" */ '../views/back/patient/Index.vue'),
    },
    {
        path: '/admin/liste-attente',
        name: 'Liste d\'attente',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "ListeAttente" */ '../views/back/liste_attente/Index.vue'),
    },
    {
        path: '/admin/rendez-vous',
        name: 'Rendez-vous',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "RendezVous" */ '../views/back/rendez_vous/Index.vue'),
    },
    {
        path: '/admin/traductions',
        name: 'Traductions',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "Patient" */ '../views/back/traduction/Index.vue'),
    },
    {
        path: '/admin/messages-personnalises',
        name: 'Messages',
        meta: {layout: 'admin', requiresAuth: true, viewBackend: true},
        component: () => import(/* webpackChunkName: "Patient" */ '../views/back/doctor-message/Index.vue'),
    },
]

const router = new VueRouter({
    linkActiveClass: 'active',
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

const $can = (permissionName)=> {
    let user = store.getters['auth/getUser']
    return user && user.all_permissions.indexOf(permissionName) !== -1;
}

router.beforeEach(async (to, from, next) => {
    let token = localStorage.getItem('api_token')
    let user_id = localStorage.getItem('user_id')
    let loggedAs = localStorage.getItem('loggedAs')
    let user = store.getters['auth/getUser']

    if(!user && token && user_id) {
        try {
            let res = await authApi.getAccountDetails(user_id, token)
            user = res.data
            if(user && user.api_token) {
                await store.dispatch('auth/setUser', user)
                await store.dispatch('auth/setIsLoggedIn', true)
                await store.dispatch('auth/setApiToken', res.data.api_token)
            }
            if(loggedAs) {
                await store.dispatch('auth/setIsLoggedAsSomeoneElse', true)
            }
        }
        catch (e) {
            await store.dispatch('auth/logOut')
        }
    }

    if (to.matched.some(record => record.meta.requiresAuth) && !user) {
        next(from)
        Vue.$toast.open({
            message: 'Veuillez vous connecter.',
            type: 'warning',
        });
    }

    //si admin => accès à tous les backend, si permission view backend, accès au backend de l'établissement de l'utilisateur
    if (to.matched.some(record => record.meta.viewBackend) && ((!$can('view backend') || user.app_id !== Vue.$config.app_id) && !user.isAdmin)) {
        next('/')
        Vue.$toast.open({
            message: 'Vous n\'avez pas accès à cette resource.',
            type: 'warning',
        });
    }

    next()
})

export default router
