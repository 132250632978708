export default class EWeekDays {

    static monday = 1
    static thuesday = 2
    static wednesday = 3
    static thursday = 4
    static friday = 5
    static saturday = 6
    static sunday = 0
    static sundayIso = 7

    static getList() {
        return [
            {
                label: 'Lundi',
                id: this.monday
            },
            {
                label: 'Mardi',
                id: this.thuesday
            },
            {
                label: 'Mercredi',
                id: this.wednesday
            },
            {
                label: 'Jeudi',
                id: this.thursday
            },
            {
                label: 'Vendredi',
                id: this.friday
            },
            {
                label: 'Samedi',
                id: this.saturday
            },
            {
                label: 'Dimanche',
                id: this.sunday
            },
        ]
    }

    static getWorkingDays() {
        return this.getList().filter(d=>d.id !== 0)
    }
}
