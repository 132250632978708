<template>
    <div class="modal fade" ref="genericModalConfirmation" id="generic-modal-confirmation"  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" :class="setting.headerClass">
                    <h5>{{setting.title}}</h5>
                </div>
                <div class="modal-body" v-html="setting.content">

                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary mr-3 btn-sm" @click="onDismiss">Annuler</button>
                    <button class="btn btn-danger btn-sm" @click="onConfirm">Confirmer</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../event-bus";

    export default {
        name: "ModalConfirmation",
        data() {
            return {
                setting: {
                    title: 'Confirmation',
                    headerClass: 'table-danger',
                    content: 'Veuillez confirmer votre action.',
                    dismissable: false,
                    object: {}
                }
            }
        },
        methods: {
            onConfirm() {
                EventBus.$emit('result-generic-modal-confirmation', {result: true, object: this.setting.object})
                $('#generic-modal-confirmation').modal('hide')
            },
            onDismiss() {
                EventBus.$emit('result-generic-modal-confirmation', {result:false})
                $('#generic-modal-confirmation').modal('hide')
            },
        },
        mounted() {
            EventBus.$on('open-generic-modal-confirmation', (data)=> {
                this.setting=Object.assign(this.setting, data)
                $('#generic-modal-confirmation').modal({backdrop:!this.setting.dismissable ? 'static' : true})
                $('#generic-modal-confirmation').modal('show')
            })
        },
        beforeDestroy() {
            EventBus.$off('open-generic-modal-confirmation')
        }
    }
</script>

<style scoped>

</style>
