<template>
    <div class="modal fade" id="opening-hour-delete-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-danger">
                <div class="modal-header table-danger">
                    <h5 class="modal-title"><i class="bi bi-exclamation-triangle-fill mr-3"></i>Suppression d'horaire</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="text-danger">
                        Veuillez confirmer la suppression de l'horaire. <br>
                        Cette action est irréversible.
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="close-edit-modal" ref="closeDeleteModal" type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" @click="onDismiss">Annuler</button>
                    <button type="button" class="btn btn-danger btn-sm" @click="onConfirmation" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        <span v-if="loading" >Chargement...</span>
                        <span v-if="!loading"><i class="bi bi-check mr-3"></i>Confirmer
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../../event-bus";

    export default {
        name: "OpeningHourDeleteModal",
        data() {
            return {
                loading: false
            }
        },
        methods: {
            onConfirmation() {
                this.loading = true
                EventBus.$emit('opening-hour-delete-confirmation', true)
            },
            onDismiss() {
                EventBus.$emit('opening-hour-delete-confirmation', false)
            }
        },
        mounted() {
            EventBus.$on('opening-hour-delete-close-modal', ()=> {
                this.loading = false
                $('#opening-hour-delete-modal').modal('hide')
            })
        },
        beforeDestroy() {
            EventBus.$off('opening-hour-delete-close-modal')
        }
    }
</script>

<style scoped>

</style>
