export default class EModule {
    static calendrier = 1
    static patient = 2
    static traduction = 3
    static dashboard = 4
    static horaires = 5
    static liste_attente = 6
    static rendez_vous = 7
    static messages_perso = 8

    static getList() {
        return [
            {
                label: 'Calendrier',
                id: this.calendrier
            },
            {
                label: 'Patient',
                id: this.patient
            },
            {
                label: 'Traduction',
                id: this.traduction
            },
            {
                label: 'Dashboard',
                id: this.dashboard
            },
            {
                label: 'Horaires',
                id: this.horaires
            },
            {
                label: 'Liste d\'attente',
                id: this.liste_attente
            },
            {
                label: 'Rendez-vous',
                id: this.rendez_vous
            },
            {
                label: 'Messages personnalisés',
                id: this.messages_perso
            },
        ]
    }
}
