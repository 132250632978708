<template>
    <nav class="navbar fixed-top  navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/">
                {{config.etablissement.display_name}}
            </router-link>
            <button class="navbar-toggler" type="button"  aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon" @click="toggled = !toggled"></span>
            </button>
            <div class="collapse navbar-collapse" :class="toggled ? 'show' : ''" ref="navbarContent" id="navbarSupportedContent">
                <ul class="navbar-nav  mr-auto mb-2 mb-lg-0 ">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/prise-de-rendez-vous"><i class="bi bi-calendar-week-fill mr-2"></i>{{$t('navigation.prise-de-rendez-vous')}}</router-link>
                    </li>
                    <li v-if="getIsLoggedIn" class="nav-item">
                        <router-link class="nav-link" to="/mes-rendez-vous"><i class="bi bi-list-ul mr-2"></i>{{$t('navigation.mes-rendez-vous')}}</router-link>
                    </li>
                    <li v-if="getIsLoggedIn" class="nav-item">
                        <router-link class="nav-link" to="/mon-compte"><i class="bi bi-person-fill mr-2"></i>{{$t('navigation.mon-compte')}}</router-link>
                    </li>
                </ul>
                <form class="d-flex">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                        <locale-switcher v-if="config.etablissement.modules.find(m=>m.id === EModule.traduction)" :flag="false" class="ml-3 mr-3 text-white"></locale-switcher>
                        <li v-if="!getIsLoggedIn" class="nav-item">
                            <router-link  class="nav-link" to="/inscription"><i class="bi bi-chevron-double-right mr-2"></i>{{$t('navigation.inscription')}}</router-link>
                        </li>
                        <li v-if="!getIsLoggedIn" class="nav-item">
                            <span class="nav-link clickable" data-toggle="modal" data-target="#connexionModal"><i class="bi bi-box-arrow-in-right mr-2"></i>{{$t('navigation.connexion')}}</span>
                        </li>

                        <li v-if="getUser && (getUser.isAdmin || $can('view backend') && getUser.app_id === config.app_id)" class="nav-item">
                            <router-link  to="/admin/calendrier" class="nav-link" ><i class="bi bi-shield-lock-fill mr-2"></i>{{$t('navigation.administration')}}</router-link>
                        </li>
                        <li v-if="getUser" class="nav-item">
                            <a class="nav-link text-danger" type="button" @click="onLogout"><i class="bi bi-box-arrow-right mr-2"></i>{{$t('navigation.deconnexion')}}</a>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
        <connexion></connexion>
    </nav>
</template>

<script>
    import Connexion from '../front/Connexion'
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../store/namespaces";
    import { EventBus, EventList } from '../../event-bus.js';
    import LocaleSwitcher from "./LocaleSwitcher";
    import permissionMixin from "@/mixins/permissionMixin.vue";
    import EModule from "@/enums/EModule";
    export default {
        name: "NavBar",
        mixins: [
            permissionMixin
        ],
        components: {
            Connexion,
            LocaleSwitcher
        },
        data() {
            return {
                config: Vue.$config,
                toggled: false
            }
        },
        computed: {
            EModule() {
                return EModule
            },
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
            }),
        },
        methods: {
            ...mapActions({
                setIsLoggedIn: namespaces.auth + 'setIsLoggedIn',
                setUser: namespaces.auth + 'setUser',
                setApiToken: namespaces.auth + 'setApiToken',
                logOut: namespaces.auth+'logOut',
                logIn: namespaces.auth + 'logIn',
            }),
            onLogout() {
                this.logOut()
                EventBus.$emit(EventList.calendar_front.refresh)
                Vue.$toast.open({
                    message: 'Vous êtes maintenant déconnecté',
                    type: 'info',
                });
                this.$router.push("/").catch(()=>{})
            }
        },
        mounted() {
            this.$refs.navbarContent.classList.remove('show')
        },
        watch: {
            '$route' () {
                this.toggled = false
            }
        }
    }
</script>

<style scoped lang="scss">
    @media(max-width: 768px) {
        .dropdown-menu {
            background-color: transparent;
            .dropdown-item {
                color: white;
            }

        }
    }
    .navbar {
        min-height: 57px;
    }

</style>
