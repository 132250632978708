import Vue from "vue";
import moment from "moment";

Vue.filter('dateField', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
})
Vue.filter('dateTimeField', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DDTkk:mm')
    }
})
Vue.filter('displayDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
})
Vue.filter('displayTime', function(value) {
    if (value) {
        return moment(value, 'HH:mm:SS').format('HH:mm')
    }
})
Vue.filter('displayTimestampDateTime', function(value) {
    if (value) {
        return moment.unix(value).format('DD/MM/YYYY HH:mm')
    }
})
Vue.filter('displayDateTime', datetime => {
    if(datetime) {
        return moment(datetime).format('DD/MM/YYYY [à] HH:mm')
    }
})

Vue.filter('displayTimestampDate', function(value) {
    if (value) {
        return moment.unix(value).format("DD/MM/YYYY");
    }
})