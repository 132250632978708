import axios from 'axios';
import store from '../store'
export default {
    getDoctors: () => {
        return axios.get('/v1/admin/doctor/get-all', {
            params: {
                app_id: Vue.$config.app_id
            },
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    getReasons: () => {
        return axios.get('/v1/admin/appointment-reason/get-for-etablissement', {
            params: {
                app_id: Vue.$config.app_id
            },
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    getWorkingDoctorsForDate:(date) => {
        return axios.post('/v1/admin/doctor/working-for-day', {
            date: date,
            app_id: Vue.$config.etablissement.id
            }, {
                headers:{
                    "content-type": "application/json",
                    "Accept": "application/json, text/plain",
                    "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }})
    },
}
