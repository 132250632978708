<template>
    <div class="card mb-0 border-danger">
        <div class="card-header table-danger d-flex justify-content-between">
            <h5 class="modal-title" id="exampleModalLabel"><i class="bi bi-exclamation-triangle-fill mr-2"></i>{{$t('calendrier-admin.suppression-rdv')}}</h5>
            <span class="clickable" @click="onDismiss"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <div v-if="appointments && appointments.length === 1" class="text-danger">
                {{$t('calendrier-admin.suppression-rdv')}}
            </div>
            <div v-else-if="appointments && appointments.length > 1" class="row">
                <div class="col-md-12">
                    <label class="form-label">{{$t('calendrier-admin.rdv-repetitifs-trouves')}} :</label>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <div class="btn btn-sm btn-primary mr-3" @click="onSelectAll"><i class="fas fa-mouse-pointer mr-2"></i>{{$t('calendrier-admin.tout-selectionner')}}</div>
                            <div class="btn btn-sm btn-primary" @click="onSelectNone"><i class="fas fa-times mr-2"></i>{{$t('calendrier-admin.tout-deselectionner')}}</div>
                        </div>
                    </div>
                    <div  class="row">
                        <template v-for="appointment in appointments">
                            <div class="col-md-4">
                                <label  class="container-checkbox">Le {{appointment.date_debut | displayDate}}
                                    <input type="checkbox" :value="appointment.id" v-model="appointment_ids">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-secondary mr-2" @click="onDismiss">{{$t('global.annuler')}}</button>
            <button type="button" class="btn btn-sm btn-danger mr-2" @click="onConfirmation" :disabled="loading || !appointment_ids || appointment_ids.length === 0">
                <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                <span v-if="loading" >{{$t('calendrier-admin.chargement')}}</span>
                <span v-if="!loading"><i class="bi bi-check mr-2"></i>{{$t('calendrier-admin.confirmer')}}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../../../store/namespaces";
    import calendarApi from '../../../../../api/calendar'
    import { EventBus } from '../../../../../event-bus'

    export default {
        name: "DeleteModal",
        props: {
            appointment: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                loading: false,
                appointments: null,
                appointment_ids: [this.appointment.id]
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
                getSelectedReason: namespaces.calendar+'getSelectedReason',
                getSelectedDoctor: namespaces.calendar+'getSelectedDoctor',
                getSelectedOffice: namespaces.calendar+'getSelectedOffice',
                getSelectedSlot: namespaces.adminCalendar+'getSelectedSlot',
            }),
        },
        methods: {
            async onConfirmation() {
                if(this.appointment_ids.length === 0) {
                    this.$toast.info(String(this.$t('calendrier-admin.warning-selection-date')))
                    return
                }
                this.loading = true
                let res = await calendarApi.deleteMultiple({appointment_ids: this.appointment_ids})
                EventBus.$emit('admin-refresh')
                this.loading = false
                this.$toast.open({
                    message: String(this.$t('calendrier-admin.rdv-supprimer-succes')),
                    type: 'success',
                });
                this.$modal.hideAll()
            },
            onDismiss() {
                this.$modal.hide('DeleteModal')
            },
            onSelectAll() {
                this.appointment_ids = []
                this.appointments.forEach(a=> {
                    this.appointment_ids.push(a.id)
                })
            },
            onSelectNone() {
                this.appointment_ids = [this.appointment.id]
            }
        },
        async mounted() {
            let res = await calendarApi.getRepeatedAppointments(this.appointment.id)
            this.appointments = res.data.appointments
        }
    }
</script>

<style scoped>

</style>
