<template>
    <div class="modal fade" id="opening-hour-create-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    Créer un horaire
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Jour(s) de la semaine</label>
                        <v-select multiple v-if="weekdays" :options="weekdays" label="label" :value="form.days" @input="onSelectDays"></v-select>
                    </div>
                    <div class="form-group" v-if="offices && offices.length > 1">
                        <label>Lieu</label>
                        <v-select :options="offices" label="name" :value="offices.find(o=> o.id===form.office_id)" @input="onSelectOffice"></v-select>
                    </div>
                    <div class="form-group" v-if="reasons">
                        <label>Motif</label>
                        <v-select multiple :options="reasons" label="label" @input="onSelectReason" :value="form.reasons"></v-select>
                    </div>
                    <div class="form-group">
                        <label>Heure de début</label>
                        <input type="time" class="form-control" v-model="form.opening_time">
                    </div>
                    <div class="form-group">
                        <label>Heure de fin</label>
                        <input type="time" class="form-control" v-model="form.closing_time">
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="onDismiss" class="btn btn-secondary btn-sm"><i class="bi bi-check mr-3"></i>Annuler</button>
                    <button @click.prevent="onSave" class="btn btn-success btn-sm">
                        <span v-if="loading" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        <span v-if="loading" >Chargement...</span>
                        <span v-if="!loading"><i class="bi bi-check mr-3"></i>Enregistrer
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../../event-bus";
    import EWeekDays from "../../../enums/EWeekDays";
    import axios from 'axios'
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    export default {
        name: "OpeningHourCreateModal",
        data() {
            return {
                weekdays: EWeekDays.getWorkingDays(),
                form: {
                    doctor_id : null,
                    office_id: null,
                    opening_time: null,
                    closing_time: null,
                    days: [],
                    reasons: [],
                },
                offices: null,
                loading: false,
                reasons: null
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getOffices: namespaces.adminCalendar+'getOffices',
            }),
        },
        methods: {
            onDismiss() {
                $('#opening-hour-create-modal').modal('hide')
                this.resetForm()
            },
            async onSave() {
                if(!this.validate()) {
                    Vue.$toast.open({
                        message: 'Veuillez compléter le formulaire',
                        type: 'info',
                    });
                    return
                }
                this.loading = true
                try {
                    let res = await axios.post('opening-hour/create', this.form, {headers: {
                            "content-type": "application/json",
                            "Accept": "application/json, text/plain",
                            "Authorization": "Bearer " + this.getUser.api_token
                        }})
                    EventBus.$emit('opening-hour-create-confirm', res.data.doctor)
                    $('#opening-hour-create-modal').modal('hide')
                    Vue.$toast.open({
                        message: 'Horaire créé avec succès !',
                        type: 'success',
                    });
                }catch(e) {
                    Vue.$toast.open({
                        message: 'Une erreur s\'est produite, veuillez vérifier votre connexion internet.',
                        type: 'error',
                    });
                    console.log(e)
                }
                this.loading = false
            },
            onSelectDays(days) {
                this.form.days = days
            },
            onSelectDay(day) {
                this.form.day_of_week = day.id
            },
            onSelectOffice(office) {
                this.form.office_id = office.id
            },
            onSelectReason(reasons) {
                this.form.reasons = reasons
            },
            resetForm() {
                this.form= {
                    doctor_id : null,
                    office_id: null,
                    opening_time: null,
                    closing_time: null,
                    days: [],
                    reasons: [],
                }
            },
            validate() {
                if(!this.form.office_id || !this.form.doctor_id
                    || !this.form.closing_time || !this.form.opening_time
                    || this.form.days.length === 0 || this.form.reasons.length === 0) {
                    return false
                }else return true
            }
        },
        mounted() {
            EventBus.$on('opening-hour-create', (data)=> {
                this.offices = this.getOffices.filter(o=> o.id !== 0)
                if(this.offices.length === 1)this.form.office_id = this.offices[0].id
                this.reasons = data.doctor.reasons
                this.form.doctor_id = data.doctor.id
            })
        }
    }
</script>

<style scoped>

</style>
