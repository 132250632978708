<template>
    <div class="position-relative">
        <nav-bar></nav-bar>
        <transition name="fade">
            <div v-if="this.$route.meta.layout === 'default' && getUser && !getUser.matricule" class="warning-matricule w-100 text-center text-white my-1 position-fixed">
                <small v-html="$t('global.warning-matricule')"></small>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="this.$route.meta.layout === 'default' && getIsLoggedAsSomeoneElse" class="warning-logged-as w-100 text-center text-white position-fixed">
                <small>Attention, vous êtes connecté en tant que <i>{{getUser.last_name + ' ' + getUser.first_name}}</i></small>
            </div>
        </transition>
        <slot></slot>
        <footer-component></footer-component>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";

    export default {
        name: "Default",
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getIsLoggedAsSomeoneElse: namespaces.auth + 'getIsLoggedAsSomeoneElse',
            }),
        },
    }
</script>

<style scoped lang="scss">
.warning-matricule {
    top: 53px;
    background-color: rgba(220, 53, 70, 0.8);
    z-index: 1000;
}
.warning-logged-as {
    bottom: 0;
    background-color: rgba(220, 53, 70, 0.8);
    z-index: 1000;
}
</style>
