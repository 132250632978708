import axios from 'axios';
import store from '../store'

export default {
    searchTerm: (term) => {
        return axios.get('v1/admin/user/search-term', {
            params: {
                term: term,
            },
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    getById: (id) => {
        return axios.get('v1/admin/user/get/'+id, {
            params: {
                app_id: Vue.$config.etablissement.id
            },
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        })
    },
    dynamicCreation: (data)=> {
        return axios.post('/v1/admin/user/create', data, {
            headers:{
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
}