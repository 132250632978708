import axios from 'axios'
import store from '../store'
export default {
    login: (credentials) => {
        return axios.post('v1/front/auth/authenticate', {
            mail: credentials.email,
            password: credentials.password
        });
    },
    logout: () => {
        return axios.get('logout');
    },
    signup: (user) => {
        return axios.post('register', user);
    },
    getAccountDetails: (userId, token) => {
        return axios.post('v1/front/auth/account', {'user_id':userId, 'app_id': Vue.$config.app_id}, {
            headers:  {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + token
            }
        });
    },
    updateAccountDetails:(data) => {
        return axios.post('account-update', data, {
            headers: {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + store.getters['auth/getUser'].api_token
            }
        });
    },
    passwordReset: (data) => {
        return axios.post("/reset/password", data)
    },
    requestPasswordReset: (data) => {
        return axios.post('auth/reset/password', data)
    }
}
