<template>
    <div class="modal fade" ref="connexionModal" id="connexionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{mode==='connexion' ? 'Connexion' : $t('global.reinitialisation-de-mot-de-passe') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <transition name="fade"  mode="out-in">
                        <div v-if="mode==='connexion'">
                            <form>
                                <label for="email">Email</label>
                                <input type="text" class="form-control" id="email" v-model="email">
                                <transition name="fade" mode="out-in">
                                    <div v-if="errors && errors.email">
                                        <div v-for="error in errors.email" class="invalid" >
                                            {{error}}
                                        </div>
                                    </div>
                                </transition>
                                <label for="password">Mot de passe</label>
                                <input type="password" class="form-control" id="password" v-model="password" autocomplete="true">
                                <transition name="fade" mode="out-in">
                                    <div v-if="errors && errors.password">
                                        <div v-for="error in errors.password" class="invalid">
                                            {{error}}
                                        </div>
                                    </div>
                                </transition>
                            </form>
                            <a class="mt-3" href="#" @click.prevent="mode='password-request'">{{$t('global.mot-de-passe-perdu')}}</a>
                        </div>
                        <div v-else>
                            <div>
                                <form>
                                    <label for="email">{{ $t('account.email') }}</label>
                                    <input type="text" class="form-control" id="email" v-model="email">
                                    <transition name="fade" mode="out-in">
                                        <div v-if="errors && errors.email">
                                            <div v-for="error in errors.email" class="invalid" >
                                                {{error}}
                                            </div>
                                        </div>
                                    </transition>
                                </form>
                            </div>
                            <a class="pt-3" href="#" @click.prevent="mode='connexion'">{{ $t('global.connexion') }}</a>
                        </div>
                    </transition>
                </div>
                <div class="modal-footer">
                    <button ref="closeConnexionModal" id="close-connexion-modal" type="button" class="btn btn-sm btn-secondary" data-dismiss="modal" @click="loading=false">{{$t('global.annuler')}}</button>
                    <button :disabled="loading" type="button" class="btn btn-sm btn-primary" @click="onSubmit">
                        <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <span v-if="loading" >{{$t('global.chargement')}}</span>
                        <span v-if="!loading && mode === 'connexion'"><i class="bi bi-box-arrow-in-right mr-2"></i>{{$t('global.connexion')}}</span>
                        <span v-if="!loading && mode === 'password-request'"><i class="bi bi-check2 mr-2"></i>{{$t('global.envoyer')}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import authApi from '../../api/auth'
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import { EventBus, EventList } from '@/event-bus';

    export default {
        name: "Connexion",
        data() {
            return {
                loading: false,
                email: null,
                password: null,
                calendarApi: null,
                mode: 'connexion',
                errors: null
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
                getApiToken: namespaces.auth + 'getApiToken',

            }),
        },
        methods: {
            ...mapActions({
                setIsLoggedIn: namespaces.auth + 'setIsLoggedIn',
                setUser: namespaces.auth + 'setUser',
                setApiToken: namespaces.auth + 'setApiToken',
            }),
            async onSubmit() {
                this.loading = true

                    if(this.mode === 'connexion') {
                        try {
                            let res = await authApi.login({
                                email: this.email,
                                password: this.password
                            })
                            await this.setApiToken(res.data.user.api_token)
                            await this.setUser(res.data.user)
                            await this.setIsLoggedIn(true)
                            EventBus.$emit(EventList.calendar_front.refresh);
                            this.$toast.open({
                                message: this.$t('account.connexion-ok'),
                                type: 'success',
                            });
                        }catch (e) {
                            this.errors = e.response.data.errors
                            if(this.errors) {
                                let string = ''
                                for (const [key, value] of Object.entries(this.errors)) {
                                    value.forEach(m=> {
                                        string += '-' + m + '<br>'
                                    })
                                }
                                Vue.$toast.open({
                                    message: this.$t('global.erreur') + '<br>' + string,
                                    type: 'error',
                                });
                            }else {
                                Vue.$toast.open({
                                    message: this.$t('account.connexion-ko'),
                                    type: 'error',
                                });
                            }
                        }
                        this.$refs['closeConnexionModal'].click()
                    }else {
                        try {
                            let res = await authApi.requestPasswordReset({
                                'email': this.email,
                                'appId': Vue.$config.app_id
                            })
                            this.$toast.open({
                                message: this.$t('account.demande-reinitialisation-mdp-ok'),
                                type: 'success',
                            });
                            this.mode = 'connexion'
                        }catch (e) {
                            this.errors = e.response.data.errors
                            if(this.errors) {
                                let string = ''
                                for (const [key, value] of Object.entries(this.errors)) {
                                    value.forEach(m=> {
                                        string += '-' + m + '<br>'
                                    })
                                }
                                this.$toast.open({
                                    message: this.$t('global.erreur') + '<br>' + string,
                                    type: 'error',
                                });
                            }else {
                                this.$toast.open({
                                    message: this.$t('global.erreur'),
                                    type: 'error',
                                });
                            }
                        }
                    }
                this.loading=false
            },
        },
        mounted() {

        }
    }
</script>

<style scoped>

</style>
