import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import layout from './modules/layout'
import calendar from "./modules/calendar";
import adminCalendar from './modules/adminCalendar'

Vue.use(Vuex)


export default new Vuex.Store({
    strict: true,
    modules: {
        auth: auth,
        layout: layout,
        calendar: calendar,
        adminCalendar: adminCalendar
    }
});
