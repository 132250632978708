<template>
    <footer class="c-footer c-footer-dark">
        <a href="https://bms-engineering.lu" target="_blank">
            <img class=" hide-desktop" style="max-width: 75px" src="@/assets/logo_medical_medium.png" alt="Logo BMS Medical">
        </a>
        <div class="container-fluid hide-mobile">
            <div class="row">
                <div class="col-md-12 d-flex justify-content-end align-items-center">
                    <div class="mr-3">
                        <a href="https://bms-engineering.lu" target="_blank">
                            <img style="max-width: 75px" src="@/assets/logo_medical_medium.png" alt="Logo BMS Medical">
                        </a>
                    </div>
                    <div class="footer-text">
                        Prise de rendez-vous en ligne © 2019-{{moment().format('Y')}}. <br>
                        Développé par <a href="https://bms-engineering.lu" target="_blank">BMS Engineering</a><br>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "AdminFooter",
        data() {
            return {
                moment: moment
            }
        }
    }
</script>

<style scoped>
    footer {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        /*padding: 10px 0;*/
    }
    .footer-text {
        font-size: 12px;
    }
</style>
