<template>
    <li class="nav-item locale-switcher dropdown d-flex align-items-center">
        <a v-if="flag" class="flag nav-link" type="button" id="flag-dropdown-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            :style="{ backgroundImage: 'url(' + src + ')' }"
        >
        </a>
        <a v-else class="nav-link" type="button" id="flag-dropdown-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="bi bi-translate"></i>
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a  @click.prevent="onSetLocale('fr')" href="#" class=" dropdown-item"><img class="flag"  src="@/assets/flags/fr.png" alt=""></a>
            <a @click.prevent="onSetLocale('en')" href="#" class="dropdown-item"><img class="flag"  src="@/assets/flags/en.png" alt="" ></a>
            <a @click.prevent="onSetLocale('de')" href="#" class="dropdown-item"><img class="flag"  src="@/assets/flags/de.png" alt="" ></a>
            <a @click.prevent="onSetLocale('pt')" href="#" class="dropdown-item"><img class="flag"  src="@/assets/flags/pt.png" alt="" ></a>
        </div>
    </li>
</template>

<script>
import {EventBus, EventList} from "@/event-bus";
import {mapActions, mapGetters} from "vuex";
import {namespaces} from "@/store/namespaces";
import permissionMixin from "@/mixins/permissionMixin.vue";
import axios from "axios";

export default {
    name: "LocaleSwitcher",
    mixins: [
        permissionMixin
    ],
    props: {
        flag: {
            type: Boolean,
            required: false,
            default() {
                return true
            }
        }
    },
    computed: {
        ...mapGetters({
            getUser: namespaces.auth + 'getUser',
        }),
        src() {
            return require('@/assets/flags/'+this.$i18n.locale+'.png')
        }
    },
    methods: {
        ...mapActions({
            setAdminDoctors: namespaces.adminCalendar+'setDoctors',
            setDoctors: namespaces.calendar+'setDoctors',
        }),
        onSetLocale(locale) {
            axios.defaults.params = {
                locale: locale
            }
            this.$i18n.locale = locale
            localStorage.setItem('locale', locale)
            if(this.$can('view backend')
                || this.getUser && this.getUser.isDoctor
                || this.getUser && this.getUser.isAdmin
            ) {
                this.setAdminDoctors()
            }
            this.setDoctors()
            EventBus.$emit(EventList.translation.setLocale, locale)
        }
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
.flag {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.locale-switcher {
    a {
        color: unset;
    }
}
</style>
