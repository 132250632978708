<template>
    <div class="card mb-0" ref="createModale" id="create-modale">
        <div class="card-header d-flex justify-content-between">
            <h5 class="modal-title">{{$t('calendrier-admin.creation-rdv')}}</h5>
            <span class="clickable" @click="onDismiss"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <div v-if="getHasDoubleCalendarMsg" class="alert alert-warning">
                <div v-html="getHasDoubleCalendarMsg"></div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right">
                    <div class="d-flex justify-content-end align-items-center">
                        <label class="form-label mr-2">{{$t('calendrier-admin.rdv-repetitif')}}</label>
                        <label for="repeated" class="switch">
                            <input type="checkbox"  id="repeated" class="switch-input"
                                   @click="repeated = !repeated"
                                   :checked="repeated"
                            >
                            <span class="switch-slider"></span>
                        </label>
                    </div>
                </div>
            </div>
            <transition name="fade" mode="out-in">
                <div v-if="!repeated">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="create-date-debut">{{$t('calendrier-admin.debut')}}</label>
                            <input id="create-date-debut" type="datetime-local" class="form-control" @input="e=> form.date_debut=e.target.value" :value="form.date_debut">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="create-date-fin">{{$t('calendrier-admin.fin')}}</label>
                            <input id="create-date-fin" type="datetime-local" class="form-control" @input="e=> form.date_fin=e.target.value" :value="form.date_fin">
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="start-time">{{$t('calendrier-admin.heure-debut')}} :</label>
                            <input id="start-time" type="time" class="form-control" @input="e=> form.start_time=e.target.value" :value="form.start_time">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="end-time">{{$t('calendrier-admin.heure-fin')}} :</label>
                            <input id="end-time" type="time" class="form-control" @input="e=> form.end_time=e.target.value" :value="form.end_time">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label for="start-time">{{$t('calendrier-admin.a-partir-du')}} :</label>
                            <input id="start-time" type="date" class="form-control" @input="e=> form.start=e.target.value" :value="form.start | dateField">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label for="end-time">{{$t('calendrier-admin.jusqu-au')}} :</label>
                            <input id="end-time" type="date" class="form-control" @input="e=> form.end=e.target.value" :value="form.end | dateField">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">{{$t('calendrier-admin.repeter')}} :</label>
                            <div class="row">
                                <template v-for="(day, index) in EWeekDays.getList()" >
                                    <div class="col-md-4">
                                        <label  class="container-checkbox">{{day.label}}
                                            <input type="checkbox" :value="day.id" v-model="form.days_to_repeat">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">{{$t('calendrier-admin.mode')}} :</label>
                            <v-select :options="EPeriodicite.getList()" label="label" @input="p=> this.form.periodicite = p.id" :value="EPeriodicite.getList().find(p=> p.id===form.periodicite)"></v-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <hr>
                        </div>
                    </div>
                </div>
            </transition>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <transition name="fade" mode="out-in">
                        <div class="d-flex flex-column justify-content-end h-100" v-if="getDoctors" key="show">
                            <label for="doctor" class="form-label">{{$t('global.docteur')}}</label>
                            <v-select id="doctor" :options="getDoctors" label="label" @input="d=> d ? form.doctor_id = d.id : form.doctor_id = null" :value="getDoctors.find(d=>d.id === form.doctor_id)"></v-select>
                        </div>
                    </transition>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="row">
                        <div class="col-md-9">
                            <label for="user" class="form-label">{{$t('calendrier-admin.patient')}} <em>{{$t('calendrier-admin.nom-prenom-matricule')}}</em><br><em>{{$t('calendrier-admin.introduire-min-3')}}</em></label>
                        </div>
                        <div v-if="config.etablissement.create_dynamic_patient" class="col-md-3">
                            <a class="btn btn-primary btn-sm" @click="openCreateUserModal">{{$t('calendrier-admin.creation-patient')}}</a>
                        </div>
                    </div>
                    <v-select id="user" @search="onSearchUser" :options="userList" label="label" @input="onInputUser" :value="userList.find(u=>u.id===form.user_id)">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                {{$t('calendrier-admin.aucun-resultat-pour')}} : <em>{{ search }}</em
                            >.
                            </template>
                            <em v-else style="opacity: 0.5">{{$t('calendrier-admin.introduire-min-3')}}</em>
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <transition name="fade" mode="out-in">
                        <div class="w-100" v-if="getReasons" key="show">
                            <label for="reason" class="form-label">{{$t('calendrier-admin.motif')}}</label>
                            <v-select id="reason" class="w-100"
                                      :options="getReasons"
                                      label="label"
                                      @input="r=> r ? this.form.reason_id = r.id : this.form.reason_id = null"
                                      :value="getReasons.find(r=> r.id===form.reason_id)"
                            ></v-select>
                        </div>
                    </transition>
                </div>
                <div  class="col-md-6 mb-3">
                    <transition name="fade" mode="out-in">
                        <div v-if="getOffices" key="show">
                            <label for="office" class="form-label">{{$t('calendrier-admin.lieu')}}</label>
                            <v-select id="office"
                                      :options="getOffices"
                                      label="name"
                                      @input="o=> o ? this.form.office_id = o.id : this.form.office_id = null"
                                      :value="getOffices.find(o=> o.id === form.office_id)"
                            ></v-select>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-3">
                    <div>
                        <label class="form-label" for="comment">{{$t('calendrier-admin.commentaire')}} :</label>
                        <textarea  name="comment" id="comment" cols="30" rows="2" class="form-control" v-model="form.comment" maxlength="120"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div>
                        <label for="note" class="form-label">{{$t('calendrier-admin.note')}} :</label>
                        <textarea name="note" id="note" rows="2" class="form-control" v-model="form.note"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <button @click="onDismiss" id="close-create-modal" type="button" class="btn btn-secondary btn-sm mr-2">{{$t('global.annuler')}}</button>
            <button type="button" class="btn btn-primary btn-sm" @click="onConfirmation" :disabled="!validate()">
                <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                <span v-if="loading" >{{$t('global.chargement')}}</span>
                <span v-if="!loading"><i class="bi bi-check mr-2"></i>{{$t('global.confirmer')}}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "../../../../../store/namespaces";
    import {EventBus} from '../../../../../event-bus'
    import calendarApi from '../../../../../api/calendar'
    import userApi from '../../../../../api/user'
    import EWeekDays from "../../../../../enums/EWeekDays";
    import EPeriodicite from "../../../../../enums/EPeriodicite";
    import moment from "moment";
    import CreateUserModal from "../user/CreateUserModal"

    export default {
        name: "CreateModal",
        data() {
            return {
                config: Vue.$config,
                loading: false,
                repeated: false,
                userList: [],
                comment: null,
                EWeekDays: EWeekDays,
                EPeriodicite: EPeriodicite,
                actualDay: null,
                form: {
                    app_id: Vue.$config.etablissement.id,
                    date_debut: null,
                    date_fin: null,
                    reason_id: null,
                    doctor_id: null,
                    user_id: null,
                    office_id: null,
                    comment: null,
                    note: null,
                    days_to_repeat: [

                    ],
                    periodicite: EPeriodicite.hebdomadaire,
                    start: null,
                    end: null,
                    start_time: null,
                    end_time: null
                },
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
                getOffices: namespaces.adminCalendar + 'getOffices',
                getOfficeList: namespaces.adminCalendar + 'getOfficeList',
                getDoctors: namespaces.adminCalendar + 'getDoctors',
                getDoctorList: namespaces.adminCalendar + 'getDoctorList',
                getReasons: namespaces.adminCalendar + 'getReasons',
                getSelectedReason: namespaces.adminCalendar+'getSelectedReason',
                getSelectedDoctor: namespaces.adminCalendar+'getSelectedDoctor',
                getSelectedOffice: namespaces.adminCalendar+'getSelectedOffice',
                getSelectedSlot: namespaces.adminCalendar+'getSelectedSlot',
                getHasDoubleCalendarMsg: namespaces.adminCalendar+'getHasDoubleCalendarMsg'
            }),
        },
        methods: {
            ...mapActions({
                setHasDoubleCalendarMsg: namespaces.adminCalendar+'setHasDoubleCalendarMsg',
            }),
            init() {
                this.form.date_debut =  this.getSelectedSlot && this.getSelectedSlot.start? moment(this.getSelectedSlot.start).format('YYYY-MM-DD HH:mm') : null
                this.form.date_fin =  this.getSelectedSlot && this.getSelectedSlot.end? moment(this.getSelectedSlot.end).format('YYYY-MM-DD HH:mm') : null
                this.form.start =  this.getSelectedSlot && this.getSelectedSlot.start? this.getSelectedSlot.start : null
                this.form.start_time =  this.getSelectedSlot && this.getSelectedSlot.start? moment(this.getSelectedSlot.start).format('HH:mm') : null
                this.form.end_time =  this.getSelectedSlot && this.getSelectedSlot.end? moment(this.getSelectedSlot.end).format('HH:mm') : null
                this.form.reason_id = this.getSelectedReason ? this.getSelectedReason.id : null
                this.form.office_id = this.getSelectedOffice ? this.getSelectedOffice.id : null
                this.form.doctor_id = this.getSelectedSlot && this.getSelectedSlot.resource ?
                    (this.getDoctors.find(d=>d.id === parseInt(this.getSelectedSlot.resource.id)) ? this.getDoctors.find(d=>d.id === parseInt(this.getSelectedSlot.resource.id)).id : null)
                    : this.getSelectedDoctor ? this.getSelectedDoctor.id : null
                this.actualDay = moment(this.getSelectedSlot.start).day()
                this.form.days_to_repeat.push(this.actualDay)
            },
            async onConfirmation() {
                try {
                    if(this.repeated) {
                        if(!this.form.start_time || !this.form.end_time) {
                            this.$toast.info(String(this.$t('calendrier-admin.warning-heure')))
                            return
                        }
                        if(!this.form.days_to_repeat || this.form.days_to_repeat.length === 0) {
                            this.$toast.info(String(this.$t('calendrier-admin.warning-jour')))
                            return
                        }
                        if(!this.form.periodicite) {
                            this.$toast.info(String(this.$t('calendrier-admin.warning-periodicite')))
                            return
                        }
                        if(!this.form.start || !this.form.end) {
                            this.$toast.info(String(this.$t('calendrier-admin.warning-date')))
                            return
                        }
                        this.loading = true
                        let res = await calendarApi.storePeriodic(this.form)
                    } else {
                        this.loading = true
                        let res = await calendarApi.forceStore(this.form)
                    }
                    this.onDismiss()
                    EventBus.$emit('admin-refresh')
                    this.$toast.open({
                        message: String(this.$t('calendrier-admin.succes-creation-rdv')),
                        type: 'success',
                    });
                } catch(e) {
                    this.$toast.open({
                        message: String(this.$t('global.erreur-connexion')),
                        type: 'error',
                    });
                }
                this.loading = false
            },
            onDismiss() {
                this.$modal.hide('CreateModal')
                this.setHasDoubleCalendarMsg({doctor_id: this.form.doctor_id, user_id: 0})
            },
            async onSearchUser(search, loading) {
                if(search.length === 0) {
                    this.userList = []
                }

                if(search.length > 2) {
                    let res = await userApi.searchTerm(search)
                    this.userList = res.data.users
                }
            },
            onInputUser(u) {
                if(u) {
                    this.form.user_id = u.id
                    this.setHasDoubleCalendarMsg({doctor_id: this.form.doctor_id, user_id: this.form.user_id})
                } else {
                    this.form.user_id = null
                    this.setHasDoubleCalendarMsg({doctor_id: this.form.doctor_id, user_id: 0})
                }
            },
            validate() {
                return !(!this.form.date_debut || !this.form.date_fin || !this.form.doctor_id);
            },
            openCreateUserModal() {
                this.$modal.show(CreateUserModal,{
                },{
                    name: 'CreateUserModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade',
                    width:'600px'
                })
            }
        },
        mounted() {
            this.init()
            EventBus.$on('user-creation', (user, id) => {
                this.onSearchUser(user.matricule)
                setTimeout(() => {
                    if(this.userList.length === 0) {
                        this.onSearchUser(user.email)
                    }
                }, 500);

                this.form.user_id = id
            })
        },
        beforeDestroy() {
            this.userList = []
            this.selectedUser = null
            EventBus.$off('user-creation')
        }
    }
</script>

<style scoped>
    .btn-primary, .btn-primary:hover {
        color: #ffffff;
        font-size: 0.85rem;
    }
</style>
