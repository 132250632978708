<template>
    <CSidebar
            fixed
            :minimize="getSidebarMinimise"
            :show="getSidebarShow"
            @update:show="(value) => set({state: 'sidebarShow', value: value})"
    >
        <CSidebarBrand class="d-md-down-none" to="/admin/dashboard">
            <img class="img-fluid" style="max-height: 50px" src="@/assets/logo_medical_medium.png"
                 alt="Logo BMS Medical">
            <!--
          <CIcon
                  class="c-sidebar-brand-full"
                  name="logo"
                  size="custom-size"
                  :height="35"
                  viewBox="0 0 556 134"
          />
          -->
            <CIcon
                    class="c-sidebar-brand-minimized"
                    name="logo"
                    size="custom-size"
                    :height="35"
                    viewBox="0 0 110 134"
            />
        </CSidebarBrand>
        <!--
        <CSidebarNavItem
                name="Dashboard"
                to="/admin/dashboard"
                icon="cil-speedometer">
        </CSidebarNavItem>
        -->
        <!--<CRenderFunction flat :content-to-render="filteredNavigation"/>-->
        <!--<CRenderFunction flat :content-to-render="nav"/>-->
        <CSidebarNav>
            <CScrollbar>
                <CSidebarNavItem v-if="moduleActivated(EModule.dashboard)" name="Dashboard" icon="cil-speedometer" to="/admin/dashboard">
                </CSidebarNavItem>
                <CSidebarNavDropdown
                    v-if="!getSidebarMinimise && moduleActivated(EModule.calendrier) &&  getDoctors && getDoctors.length > 1"
                    :show="getSidebarShowCalendar"
                    @update:show="(value) => set({state: 'sidebarShowCalendar', value: value})"
                    name="Calendrier" font-icon="bi bi-calendar-week">
                    <template v-if="getResources">
                    <li  class="c-sidebar-nav-item" >
                        <div @click="onAllDoctors"
                             class="d-flex justify-content-between clickable c-sidebar-nav-link"
                             :class="getSelectedDoctor && getSelectedDoctor.id === 0 && isCalendarViewActive() ? 'c-active' : null"
                        >
                            <span style="color: #3F51B5" class="clickable" :class="getSelectedDoctor && getSelectedDoctor.id === 0 ? 'text-success' : 'text-white'">
                                <i class="fas fa-chevron-circle-right"></i>
                                <span> Tous les {{$t('global.docteur')}}s</span>
                            </span>
                        </div>
                    </li>
                    <li v-for="doctor in getDoctors" class="c-sidebar-nav-item" >
                        <div style="padding-left: 56px" class="d-flex justify-content-between c-sidebar-nav-link"
                             :class="getSelectedDoctor && getSelectedDoctor.id === doctor.id && isCalendarViewActive() ? 'c-active' : null">
                            <router-link :to="'/admin/calendrier/'+doctor.id"
                                         class="text-decoration-none"
                                         :class="getSelectedDoctor && getSelectedDoctor.id === doctor.id && isCalendarViewActive() ? 'text-success' : 'text-white'">
                                <i class="fas fa-chevron-circle-right"></i>
                                {{doctor.name}}
                            </router-link>
                            <span @click="onToggleResource(doctor)" class="clickable">
                                <i class="mr-2"
                                   :class="getResources.find(r=> r.id===doctor.id) ? 'fas fa-calendar-check text-success' : 'fas fa-calendar-times text-danger'"
                                ></i>
                            </span>
                        </div>
                    </li>
                    </template>
                </CSidebarNavDropdown>
                <CSidebarNavItem v-else-if="moduleActivated(EModule.calendrier)" name="Calendrier" font-icon="bi bi-calendar-week" to="/admin/calendrier">
                </CSidebarNavItem>
                <CSidebarNavItem v-if="moduleActivated(EModule.horaires)" name="Horaires" font-icon="bi bi-alarm" to="/admin/horaires">
                </CSidebarNavItem>
                <CSidebarNavItem v-if="moduleActivated(EModule.liste_attente)" name="Liste d'attente" font-icon="bi bi-hourglass-split" to="/admin/liste-attente">
                </CSidebarNavItem>
                <CSidebarNavItem v-if="moduleActivated(EModule.rendez_vous)" name="Rendez-vous" font-icon="bi bi-list-ul" to="/admin/rendez-vous">
                </CSidebarNavItem>
                <CSidebarNavItem v-if="moduleActivated(EModule.traduction)" name="Traductions" font-icon="bi bi-spellcheck" to="/admin/traductions">
                </CSidebarNavItem>
                <CSidebarNavItem v-if="moduleActivated(EModule.messages_perso)" name="Messages personnalisés" font-icon="bi bi-chat-dots" to="/admin/messages-personnalises">
                </CSidebarNavItem>
            </CScrollbar>
        </CSidebarNav>
        <CSidebarMinimizer
                class="d-md-down-none"
                @click.native="set({state: 'sidebarMinimize', value: !getSidebarMinimise})"
        />
    </CSidebar>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import {EventBus} from "@/event-bus";
    import EModule from '../../enums/EModule'

    export default {
        name: 'Sidebar',
        data() {
            return {
                EModule: EModule,
            }
        },
        computed: {
            ...mapGetters({
                getSidebarShow: namespaces.layout + 'getSidebarShow',
                getSidebarShowCalendar: namespaces.layout + 'getSidebarShowCalendar',
                getSidebarMinimise: namespaces.layout + 'getSidebarMinimise',
                getUser: namespaces.auth + 'getUser',
                getDoctors: namespaces.adminCalendar + 'getDoctors',
                getResources: namespaces.adminCalendar + 'getResources',
                getDoctorList: namespaces.adminCalendar + 'getDoctorList',
                getSelectedDoctor: namespaces.adminCalendar + 'getSelectedDoctor',
            }),
        },
        methods: {
            ...mapActions({
                set: namespaces.layout + 'set',
                toggleResource: namespaces.adminCalendar + 'toggleResource',
                setCurrentView: namespaces.adminCalendar+'setCurrentView',
                setSelectedDoctor: namespaces.adminCalendar+'setSelectedDoctor',
                setDisplayOnlyWorkingDoctors: namespaces.adminCalendar+'setDisplayOnlyWorkingDoctors',
            }),
            isCalendarViewActive() {
                return this.$route.name === 'Calendrier'
            },
            moduleActivated(moduleId) {
                return !!Vue.$config.etablissement.modules.find(m=> parseInt(m.id) === parseInt(moduleId))
            },
            onAllDoctors() {
                this.setSelectedDoctor(this.getDoctorList.find(d=>d.id === 0))
                this.setCurrentView('resourceTimeGridDay')
                EventBus.$emit('admin-calendar-set-view', 'resourceTimeGridDay')
                EventBus.$emit('admin-refresh')
                this.$router.push('/admin/calendrier').catch(()=>{})
            },
            async onToggleResource(doctor) {
                await this.setDisplayOnlyWorkingDoctors(false)
                await this.toggleResource(doctor)
                EventBus.$emit('admin-calendar-set-option', {type: 'resources', data: this.getResources})
            }
        },
    }
</script>
