<template>
    <div ref="duplicateModal" class="card mb-0">
        <div class="card-header d-flex justify-content-between">
            <h5 class="modal-title">Duplication de rendez-vous</h5>
            <span @click="onDismiss" class="clickable"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <span>Précisez les horaires du/des nouveau(x) rendez-vous</span>
            <div class="form-group row mt-2 mb-0">
                <div class="col-md-12 text-right">
                    <button @click="addAppointment" type="button" class="btn btn-primary btn-sm"><i class="bi bi-plus-circle mr-1"></i> Rendez-vous</button>
                </div>
            </div>

            <div v-for="(appointment, index) in newAppointments" :key="`row_${index}`" class="form-group row">
                <div class="col-md-5">
                    <label for="date_debut" class="form-control-label">Début du RDV n° {{ index + 1 }}</label>
                    <input type="datetime-local" :key="`date_debut_${index}`" class="form-control" v-model="appointment.date_debut">
                </div>
                <div class="col-md-5">
                    <label for="date_fin" class="form-control-label">Fin du RDV n° {{ index + 1 }}</label>
                    <input type="datetime-local" :key="`date_fin_${index}`" class="form-control" v-model="appointment.date_fin">
                </div>
                <div v-if="index > 0" class="col-md-2 align-self-end pb-1">
                    <button @click="removeAppointment(index)" type="button" class="btn btn-danger btn-sm"><i class="bi bi-x-lg"></i></button>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <button @click="onDismiss" type="button" class="btn btn-secondary btn-sm mr-2">Annuler</button>
            <button @click="onConfirmation" type="button" class="btn btn-primary btn-sm" :disabled="!validate()">
                <span v-if="loading" class="spinner-border spinner-border-sm mr-2" aria-hidden="true"></span>
                <span v-if="loading">Chargement...</span>
                <span v-if="!loading">Dupliquer</span>
            </button>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '@/event-bus'
    import calendarApi from '../../../../../api/calendar'

    export default {
        name: "DuplicateModal",
        props: {
            appointment: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                newAppointments: []
            }
        },
        methods: {
            validate() {
                return this.validateAppointments()
            },
            validateAppointments() {
                let valid = true
                this.newAppointments.forEach(appointment => {
                    if (!appointment.date_debut || !appointment.date_fin || (appointment.date_debut >= appointment.date_fin)) {
                        valid = false
                    }
                })

                return valid
            },
            addAppointment() {
                this.newAppointments.push({...this.appointment, id: null, comment: null, created_at: null, created_by: null, updated_at: null, updated_by: null})
            },
            removeAppointment(index) {
                this.newAppointments.splice(index, 1)
            },
            async onConfirmation() {
                try {
                    this.loading = true
                    this.newAppointments.forEach(appointment => {
                        let res = calendarApi.forceStore(appointment)
                    })

                    this.onDismissAll()
                    EventBus.$emit('admin-refresh')

                    Vue.$toast.open({
                        message: 'Rendez-vous dupliqué avec succès !',
                        type: 'success',
                    });
                } catch(e) {
                    Vue.$toast.open({
                        message: 'Une erreur s\'est produite <br> Veuillez vérifier votre connexion !',
                        type: 'error',
                    });
                }

                this.loading = false
            },
            onDismiss() {
                this.$modal.hide('DuplicateModal')
            },
            onDismissAll() {
                this.$modal.hideAll()
            }
        },
        mounted() {
            this.addAppointment()
        }
    }
</script>
