<template>
    <footer class="bg-dark">
        <div class="container">
            <div class="row">
                <div class="col-md-12 d-flex justify-content-center align-items-center">
                    <div class="mr-3">
                        <a href="https://bms-engineering.lu" target="_blank">
                            <img style="max-width: 75px" src="@/assets/logo_medical_medium.png" alt="Logo BMS Medical">
                        </a>
                    </div>
                    <div>
                        Prise de rendez-vous en ligne © 2019-{{moment().format('Y')}}. <br>
                        Développé par <a href="https://bms-engineering.lu" target="_blank">BMS Engineering</a><br>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: "FooterComponent",
        data() {
            return {
                moment: moment
            }
        }
    }
</script>

<style scoped>

</style>
