import moment from 'moment'
import '../../api/configuration'
import calendarApi from '../../api/calendar'
import adminCalendarApi from '../../api/adminCalendar'
import EEventBackgroundColorMode from '../../enums/EEventBackgroundColorMode'
import authStore from './auth'

const
    namespaced = true,
    state = {
        //fullCalendarApi: null,
        configuration: {
            slotDuration:"00:15:00",
            slotMaxTime:"18:00",
            slotMinTime:"08:00",
            initialDate:null,
            hiddenDays: [],
        },
        initialized: false,
        selectedReason: null,
        selectedDoctor: null,
        selectedOffice: null,
        selectedBackgroundOffice: null,
        selectedSlot: null,
        date: moment(),
        endDate: null,
        currentView: 'resourceTimeGridDay',
        offices: null,
        officeList: null,
        doctors: null,
        doctorList: null,
        reasons: null,
        resources: null,
        displayOnlyWorkingDoctors: false,
        eventBackgroundColorMode: EEventBackgroundColorMode.office,
        eventBackgroundOffice : false,
        hasDoubleCalendarMsg: false
    },
    getters = {
        //getFullCalendarApi: state => state.fullCalendarApi,
        getAppointments: state => state.appointments,
        getConfiguration: state => state.configuration,
        getSlots: state => state.slots,
        getInitialized: state => state.initialized,
        getSelectedReason: state => state.selectedReason,
        getSelectedDoctor: state => state.selectedDoctor,
        getSelectedOffice: state => state.selectedOffice,
        getSelectedBackgroundOffice: state => state.selectedBackgroundOffice,
        getDate: state => state.date,
        getEndDate: state => state.endDate,
        getOffices: state => state.offices,
        getOfficeList: state => state.officeList,
        getDoctors: state => state.doctors,
        getDoctorList: state => state.doctorList,
        getReasons: state => state.reasons,
        getSelectedSlot: state => state.selectedSlot,
        getCurrentView: state => state.currentView,
        getResources: state => state.resources,
        getDisplayOnlyWorkingDoctors: state => state.displayOnlyWorkingDoctors,
        getEventBackgroundColorMode: state => state.eventBackgroundColorMode,
        getEventBackgroundOffice: state => state.eventBackgroundOffice,
        getHasDoubleCalendarMsg: state => state.hasDoubleCalendarMsg
    },
    actions = {
        setConfiguration: (context, data) => {
            context.commit('setConfiguration', data);
        },
        setAppointments: (context, data) => {
            context.commit('setAppointments', data);
        },
        setSlots: (context, data) => {
            context.commit('setSlots', data);
        },
        setInitialized: (context, data) => {
            context.commit('setInitialized', data)
        },
        setSelectedReason: (context, data) => {
            context.commit('setSelectedReason', data)
        },
        setSelectedDoctor: (context, data) => {
            context.commit('setSelectedDoctor', data)
        },
        setSelectedOffice: (context, data) => {
            context.commit('setSelectedOffice', data)
        },
        setSelectedBackgroundOffice: (context, data) => {
            context.commit('setSelectedBackgroundOffice', data)
        },
        setDate: (context, data) => {
            context.commit('setDate', data)
        },
        setEndDate: (context, data) => {
            context.commit('setEndDate', data)
        },
        setOffices: async (context) => {
            let res = await calendarApi.getOffices()
            let offices = res.data.data
            context.commit('setOffices', JSON.parse(JSON.stringify(offices)))
            offices.push({
                'name': i18n.t('global.tous-les-lieux'),
                'id': 0
            })
            context.commit('setOfficeList', offices)
        },
        setDoctors: async (context) => {
            let res = await adminCalendarApi.getDoctors()

            let doctors = res.data.doctors
            context.commit('setDoctors', JSON.parse(JSON.stringify(doctors)))
            if (doctors.length > 1) {
                doctors.push({
                    'id': 0,
                    'label': i18n.t('global.tous-les-docteurs')
                })
            }

            context.commit('setDoctorList', doctors)
        },
        setReasons: async (context) => {
            let res = await adminCalendarApi.getReasons()
            context.commit('setReasons', res.data.reasons)
        },
        setSelectedSlot: (context, data) => {
            context.commit('setSelectedSlot', data)
        },
        setCurrentView: (context, data) => {
            context.commit('setCurrentView', data)
        },
        setResources: async (context) => {
            let resources = []
            let doctors = null
            try {
                if(context.getters.getDisplayOnlyWorkingDoctors) {
                    let res = await adminCalendarApi.getWorkingDoctorsForDate(context.getters.getDate.format('YYYY-MM-DD'))
                    doctors = res.data.doctors
                    let limitTitle = window.matchMedia("(max-width: 768px)").matches && context.getters.getDoctors.length > 6
                    doctors.forEach((doctor, index) => {
                        resources.push({
                            'id': doctor.id,
                            'title': !limitTitle ? doctor.firstname + ' ' + doctor.name : doctor.firstname.slice(0, 1) + '. ' + doctor.name.slice(0, 2) + '.'
                        })
                    })
                    context.commit('setResources', resources)
                }else /*if(!context.getters.getResources)*/ {
                    doctors = context.getters.getDoctors.filter(d=> d.id !==0)
                    let limitTitle = window.matchMedia("(max-width: 768px)").matches && context.getters.getDoctors.length > 6
                    doctors.forEach((doctor, index) => {
                        resources.push({
                            'id': doctor.id,
                            'title': !limitTitle ? doctor.firstname + ' ' + doctor.name : doctor.firstname.slice(0, 1) + '. ' + doctor.name.slice(0, 2) + '.'
                        })
                    })
                    context.commit('setResources', resources)
                }

            }catch (e) {
                Vue.$toast.error('Une erreur s\'est produite, veuillez vérifier votre connexion internet.')
            }

        },
        toggleResource: async (context, doctor)=> {
            let resources = JSON.parse(JSON.stringify(context.getters.getResources))
            if(resources.find(r=> r.id === doctor.id)) {
                context.commit('setResources', resources.filter(r=> r.id !== doctor.id))
            }else {
                let limitTitle = window.matchMedia("(max-width: 768px)").matches && context.getters.getDoctors.length > 6
                resources.push({
                    'id': doctor.id,
                    'title': !limitTitle ? doctor.firstname + ' ' + doctor.name : doctor.firstname.slice(0, 1) + '. ' + doctor.name.slice(0, 2) + '.'
                })
                context.commit('setResources', resources)
            }
        },
        setDisplayOnlyWorkingDoctors: (context, bool)=> {
            context.commit('setDisplayOnlyWorkingDoctors', bool)
        },
        setEventBackgroundColorMode: (context, mode)=> {
            context.commit('setEventBackgroundColorMode', mode)
        },
        setEventBackgroundOffice: (context, mode)=> {
            context.commit('setEventBackgroundOffice', mode)
            localStorage.setItem('eventBackgroundOffice', mode ? 'true' : 'false')
        },
        async setHasDoubleCalendarMsg(context, data) {
            let res = await calendarApi.hasDoubleCalendarMsgAdmin(data.doctor_id, data.user_id)
            if(res.data.doctors.length > 0) {
                context.commit('setDoubleCalendarMsg', res.data.message)
            } else {
                context.commit('setDoubleCalendarMsg', false)
            }
        }
    },
    mutations = {
        setFullCalendarApi: (state, data) => {
            state.fullCalendarApi = data;
        },
        setConfiguration: (state, data) => {
            state.configuration = Object.assign(state.configuration, data)
        },
        setAppointments: (state, data) => {
            state.appointments = data;
        },
        setSlots: (state, data) => {
            state.slots = data;
        },
        setInitialized: (state, data) => {
            state.initialized = data;
        },
        setSelectedReason: (state, data) => {
            state.selectedReason = data;
        },
        setSelectedDoctor: (state, data) => {
            state.selectedDoctor = data;
        },
        setSelectedOffice: (state, data) => {
            state.selectedOffice = data;
        },
        setSelectedBackgroundOffice: (state, data) => {
            state.selectedBackgroundOffice = data;
        },
        setDate: (state, data) => {
            state.date = data;
        },
        setEndDate: (state, data) => {
            state.endDate = data;
        },
        setOffices: (state, data) => {
            state.offices = data;
        },
        setOfficeList: (state, data) => {
            state.officeList = data;
        },
        setDoctors: (state, data) => {
            state.doctors = data;
        },
        setDoctorList: (state, data) => {
            state.doctorList = data;
        },
        setReasons: (state, data) => {
            state.reasons = data;
        },
        setSelectedSlot: (state, data) => {
            state.selectedSlot = data;
        },
        setCurrentView: (state, data) => {
            state.currentView = data;
        },
        setResources: (state, data) => {
            state.resources = data;
        },
        setDisplayOnlyWorkingDoctors: (state, bool) => {
            state.displayOnlyWorkingDoctors = bool;
        },
        setEventBackgroundColorMode: (state, mode) => {
            state.eventBackgroundColorMode = mode;
        },
        setEventBackgroundOffice: (state, mode) => {
            state.eventBackgroundOffice = mode;
        },
        setDoubleCalendarMsg(context, message) {
            state.hasDoubleCalendarMsg = message;
        }
    };

export default {
    namespaced: namespaced,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
