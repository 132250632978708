<template>
    <div id="app">
        <transition name="fade" mode="out-in">
            <component v-if="getInitialized" :is="layout">
                <transition name="fade" mode="out-in">
                    <router-view/>
                </transition>
            </component>
            <div v-else class="vh-100 vw-100 d-flex justify-content-center align-items-center">
                <div class="text-center">
                    <div id="spinner" class="spinner-border" role="status">
                    </div>
                    <div class="small">{{$t('global.chargement')}}</div>
                </div>
            </div>
        </transition>
        <generic-modal-confirmation></generic-modal-confirmation>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {namespaces} from "./store/namespaces";
import GenericModalConfirmation from "./components/generic/ModalConfirmation";
import permissionMixin from "@/mixins/permissionMixin.vue";
import moment from "moment";

export default {
    name: 'App',
    mixins: [
        permissionMixin
    ],
    components: {
        GenericModalConfirmation
    },
    data() {
        return {
            title: Vue.$config.etablissement.display_name
        }
    },
    metaInfo() {
        const self = this
        return {
            title: 'Prise de rendez-vous en ligne',
            titleTemplate: '%s | E-Rdv ' + self.title
        }
    },
    computed: {
        ...mapGetters({
            getInitialized: namespaces.calendar + 'getInitialized',
            getDate: namespaces.calendar + 'getDate',
            getOffices: namespaces.calendar + 'getOffices',
            getDoctors: namespaces.calendar + 'getDoctors',
            getReasons: namespaces.calendar + 'getReasons',
            getUser: namespaces.auth + 'getUser',
            getResources: namespaces.calendar + 'getResources',
        }),
        layout() {
            return (this.$route.meta.layout || 'default') + '-layout'
        }
    },
    methods: {
        ...mapActions({
            setInitialized: namespaces.calendar + 'setInitialized',
            setEndDate: namespaces.calendar + 'setEndDate',
            setOffices: namespaces.calendar + 'setOffices',
            setDoctors: namespaces.calendar + 'setDoctors',
            setReasons: namespaces.calendar + 'setReasons',
            setResources: namespaces.calendar + 'setResources',
            setSelectedReason: namespaces.calendar + 'setSelectedReason',
            setSelectedDoctor: namespaces.calendar + 'setSelectedDoctor',
            setSelectedOffice: namespaces.calendar + 'setSelectedOffice',
            setCurrentView: namespaces.calendar + 'setCurrentView',
            setDate: namespaces.calendar + 'setDate',
        }),
        async init() {
            /**
             * On ajoute la config du nombre de jours minimum avant lequel l'utilisateur peut prendre rdv
             * Si on tombe un samedi ou un dimanche, on set la date sur le lundi qui suit.
             * On ne fait ce test que lorsque l'utilisateur vient d'arriver dans l'application
             */
            let toAdd = Vue.$config.etablissement.days_limit
            let date = moment().add(toAdd, 'days')

            if(date.day() === 6 ) date.add(2, 'days')
            else if(date.day() === 0 ) date.add(1, 'days')

            await this.setDate(date);
            await this.setInitialized(true)
        },
    },
    mounted() {
        if (!this.getInitialized) {
            this.init()
        }
    },
};
</script>
<style lang="scss">

</style>
