import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Es6Promise from 'es6-promise';
Es6Promise.polyfill()
import './scss/app.scss'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {setConfig} from './init'
import {env} from "@/env";
import axios from 'axios'
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');
import 'bootstrap-icons/font/bootstrap-icons.css'
import { registerLicense } from '@syncfusion/ej2-base';
import FloatingVue from 'floating-vue'
Vue.use(FloatingVue)
import 'floating-vue/dist/style.css'


import bootstrap from  'bootstrap/dist/js/bootstrap'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)


import moment from 'moment'
window.moment = moment;
window.Vue = Vue;
import store from './store/index'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import NavBar from './components/nav/NavBar'
import FooterComponent from './components/footer/FooterComponent'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import Default from './views/layouts/Default'
import Admin from './views/layouts/Admin'
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'
import './filters/date'
import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/vue-loading-skeleton.css"
Vue.use(VModal)
Vue.use(Skeleton)
Vue.use(VueMeta)
Vue.use(CoreuiVue);
Vue.use(VueToast, {
    position: 'top-right'
});
Vue.component('v-select', vSelect)
Vue.component('nav-bar', NavBar)
Vue.component('footer-component', FooterComponent)
Vue.component('default-layout', Default)
Vue.component('admin-layout', Admin)

Vue.config.productionTip = false
Vue.$config = env
axios.defaults.baseURL = Vue.$config.use_production_api ? Vue.$config.production_api : Vue.$config.local_api;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let locale = localStorage.getItem('locale') || 'fr'
axios.defaults.params = {
    locale: locale
}
registerLicense('ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Qdk1jXn1YcHVVQ2hY');
setConfig().then(()=> {
    let messages = Vue.$config.etablissement.translations
    const i18n = new VueI18n({
        locale: locale,
        messages,
        silentTranslationWarn: true
    })
    window.i18n = i18n
    new Vue({
        i18n,
        router,
        icons,
        render: h => h(App),
        store
    }).$mount('#app')
})


