<template>
    <div>
        <sidebar v-if="getUser"></sidebar>
        <CWrapper>
            <div class="c-body">
                <main class="c-main">
                    <transition name="fade">
                        <div v-if="getIsLoggedAsSomeoneElse" class="warning-logged-as w-100 text-center text-white position-fixed">
                            <small>Attention, vous êtes connecté en tant que <i>{{getUser.last_name + ' ' + getUser.first_name}}</i></small>
                        </div>
                    </transition>
                    <admin-nav-bar></admin-nav-bar>
                    <transition name="fade" mode="out-in">
                        <slot></slot>
                    </transition>
                </main>
            </div>
            <admin-footer></admin-footer>
        </CWrapper>
        <opening-hour-create-modal></opening-hour-create-modal>
        <opening-hour-edit-modal></opening-hour-edit-modal>
        <opening-hour-delete-modal></opening-hour-delete-modal>
    </div>
</template>

<script>
    import AdminNavBar from "../../components/nav/AdminNavBar";
    import Sidebar from "../../components/nav/Sidebar";
    import AdminFooter from "../../components/footer/AdminFooter";
    import OpeningHourEditModal from "../../components/back/horaires/OpeningHourEditModal";
    import {mapGetters} from "vuex";
    import {namespaces} from "../../store/namespaces";
    import OpeningHourDeleteModal from "../../components/back/horaires/OpeningHourDeleteModal";
    import OpeningHourCreateModal from "../../components/back/horaires/OpeningHourCreateModal";
    import CreateModale from "../../components/back/calendar/modal/create/CreateModal";
    import EditModale from "../../components/back/calendar/modal/edit/EditModal";
    import ConfirmDeleteModale from "../../components/back/calendar/modal/delete/DeleteModal";

    export default {
        name: "Admin",
        components: {
            ConfirmDeleteModale,
            EditModale,
            CreateModale,
            OpeningHourCreateModal,
            OpeningHourDeleteModal,
            OpeningHourEditModal,
            AdminFooter,
            Sidebar,
            AdminNavBar
        },
        computed: {
            ...mapGetters({
                getSidebarShow: namespaces.layout + 'getSidebarShow',
                getSidebarMinimise: namespaces.layout + 'getSidebarMinimise',
                getUser: namespaces.auth + 'getUser',
                getIsLoggedAsSomeoneElse: namespaces.auth + 'getIsLoggedAsSomeoneElse',
            }),
        },
    }
</script>

<style scoped lang="scss">
    .c-main {
        padding-top: 0!important;
    }
    .warning-logged-as {
        bottom: 0;
        background-color: rgba(220, 53, 70, 0.8);
        z-index: 1000;
    }
</style>
