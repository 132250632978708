import moment from 'moment'
import calendarApi from '../../api/calendar'
const
    namespaced = true,
    state = {
        appointments: [],
        slots: [],
        initialized: false,
        selectedReason: null,
        selectedDoctor: null,
        selectedOffice: null,
        selectedBackgroundOffice: null,
        selectedSlot: null,
        date: null,
        endDate: null,
        currentView: null,
        offices: null,
        officeList: null,
        doctors: null,
        doctorList: null,
        reasons: null,
        resources: null,
        hasDoubleCalendarMsg: false
    },
    getters = {
        getAppointments: state => state.appointments,
        getSlots: state => state.slots,
        getInitialized: state => state.initialized,
        getSelectedReason: state => state.selectedReason,
        getSelectedDoctor: state => state.selectedDoctor,
        getSelectedOffice: state => state.selectedOffice,
        getSelectedBackgroundOffice: state => state.selectedBackgroundOffice,
        getDate: state => state.date,
        getEndDate: state => state.endDate,
        getOffices: state => state.offices,
        getOfficeList: state => state.officeList,
        getDoctors: state => state.doctors,
        getDoctorList: state => state.doctorList,
        getReasons: state => state.reasons,
        getSelectedSlot: state => state.selectedSlot,
        getCurrentView: state => state.currentView,
        getResources: state => state.resources,
        getHasDoubleCalendarMsg: state=>state.hasDoubleCalendarMsg
    },
    actions = {
        setAppointments: (context, data) => {
            context.commit('setAppointments', data);
        },
        setSlots: (context, data) => {
            context.commit('setSlots', data);
        },
        setInitialized: (context, data) => {
            context.commit('setInitialized', data)
        },
        setSelectedReason: (context, data) => {
            context.commit('setSelectedReason', data)
        },
        setSelectedDoctor: (context, data) => {
            context.commit('setSelectedDoctor', data)
        },
        setSelectedOffice: (context, data) => {
            context.commit('setSelectedOffice', data)
        },
        setSelectedBackgroundOffice: (context, data) => {
            context.commit('setSelectedBackgroundOffice', data)
        },
        setDate: (context, data) => {
            context.commit('setDate', data)
        },
        setEndDate: (context, data) => {
            context.commit('setEndDate', data)
        },
        setOffices: async (context) => {
            let res = await calendarApi.getOffices()
            let offices = res.data.data
            context.commit('setOffices', [...offices])
            offices.push({
                'name': i18n.t('global.tous-les-lieux'),
                'id': 0
            })
            context.commit('setOfficeList', offices)
        },
        setDoctors: async (context) => {
            let res = await calendarApi.getDoctors()
            let doctors = res.data.data
            context.commit('setDoctors', [...doctors])
            if (doctors.length > 1 && Vue.$config.etablissement.resource_view) {
                doctors.push({
                    'id': 0,
                    'label': i18n.t('global.tous-les-docteurs')
                })
            }
            context.commit('setDoctorList', doctors)
        },
        setReasons: async (context) => {
            let res = await calendarApi.getReasonsWithOpeningHours()
            context.commit('setReasons', res.data.reasons)
        },
        setSelectedSlot: (context, data) => {
            context.commit('setSelectedSlot', data)
        },
        setCurrentView: (context, data) => {
            context.commit('setCurrentView', data)
        },
        setResources: async (context) => {
            let resources = []
            let doctors = null
            try {
                doctors = context.getters.getDoctors.filter(d=> d.id !==0)
                let limitTitle = window.matchMedia("(max-width: 768px)").matches && context.getters.getDoctors.length > 6
                doctors.forEach((doctor, index) => {
                    resources.push({
                        'id': doctor.id,
                        'title': !limitTitle ? doctor.firstname + ' ' + doctor.name : doctor.firstname.slice(0, 1) + '. ' + doctor.name.slice(0, 2) + '.'
                    })
                })
                context.commit('setResources', resources)
            }catch (e) {
                Vue.$toast.error('Une erreur s\'est produite, veuillez vérifier votre connexion internet.')
            }

        },
        async setHasDoubleCalendarMsg(context, doctor_id) {
            let res = await calendarApi.hasDoubleCalendarMsg(doctor_id)
            context.commit('setHasDoubleCalendarlendarMsg', res.data.message)
        }
    },
    mutations = {
        setAppointments: (state, data) => {
            state.appointments = data;
        },
        setSlots: (state, data) => {
            state.slots = data;
        },
        setInitialized: (state, data) => {
            state.initialized = data;
        },
        setSelectedReason: (state, data) => {
            state.selectedReason = data;
        },
        setSelectedDoctor: (state, data) => {
            state.selectedDoctor = data;
        },
        setSelectedOffice: (state, data) => {
            state.selectedOffice = data;
        },
        setSelectedBackgroundOffice: (state, data) => {
            state.selectedBackgroundOffice = data;
        },
        setDate: (state, data) => {
            state.date = data;
        },
        setEndDate: (state, data) => {
            state.endDate = data;
        },
        setOffices: (state, data) => {
            state.offices = data;
        },
        setOfficeList: (state, data) => {
            state.officeList = data;
        },
        setDoctors: (state, data) => {
            state.doctors = data;
        },
        setDoctorList: (state, data) => {
            state.doctorList = data;
        },
        setReasons: (state, data) => {
            state.reasons = data;
        },
        setSelectedSlot: (state, data) => {
            state.selectedSlot = data;
        },
        setCurrentView: (state, data) => {
            state.currentView = data;
        },
        setResources: (state, data) => {
            state.resources = data;
        },
        setHasDoubleCalendarlendarMsg(context, message) {
           state.hasDoubleCalendarMsg = message;
        }
    };

export default {
    namespaced: namespaced,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
