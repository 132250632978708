export default class EWeekDays {
    static undefined = 0
    static not_honored = 1
    static honored = 2

    static getList() {
        return [
            {
                label: 'Non défini',
                id: this.undefined
            },
            {
                label: 'Non honoré',
                id: this.not_honored
            },
            {
                label: 'Honoré',
                id: this.honored
            },
        ]
    }
}
