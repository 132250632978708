import axios from "axios/index";
import Vue from 'vue'
import {env} from "@/env";

function setConfig() {
    return new Promise(resolve => {
        if(!env.production){
            axios.get('etablissement?name=' +env.etablissement_name)
                .then(res => {
                    Vue.$config.etablissement = res.data
                    resolve()
                });
        }else{
            let host = window.location.hostname;
            let arrayHost = host.split('.');
            let firstPart = arrayHost[0].split('/');
            let subdomain = firstPart.pop();
            axios.get('etablissement?name=' + subdomain)
                .then(res => {
                    Vue.$config.etablissement = res.data
                    Vue.$config.app_id = res.data.id
                    Vue.$config.etablissement_name = host
                    resolve()
                });
        }
    })
}
export {setConfig}
