import axios from 'axios'

export default {
    store: (data, token) => {
        return axios.post('/configuration',
            {
                'app_id':  Vue.$config.app_id,
                'user_id':data.user_id,
                'key': data.key,
                'value': data.value
            },
            {
            headers:  {
                "content-type": "application/json",
                "Accept": "application/json, text/plain",
                "Authorization": "Bearer " + token
            }
        });
    },
    get: (user_id = null) => {
        return axios.get('/configuration?app_id=' + Vue.$config.app_id + (user_id ? '&user_id='+user_id : ''));
    },

}
