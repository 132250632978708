const namespaced = true

const state = {
    sidebarShow: 'responsive',
    sidebarShowCalendar: false,
    sidebarMinimize: false,
    fullscreen: false,
    title: null,
    topOptionsShown: false
}
const getters = {
    getSidebarShow: state => state.sidebarShow,
    getSidebarShowCalendar: state => state.sidebarShowCalendar,
    getSidebarMinimise: state => state.sidebarMinimize,
    getFullscreen: state => state.fullscreen,
    getTopOptionsShown: state => state.topOptionsShown
}
const actions = {
    toggleSidebarDesktop: (context) => {
        context.commit('toggleSidebarDesktop');
    },
    toggleSidebarMobile: (context) => {
        context.commit('toggleSidebarMobile');
    },
    set:(context, data) =>{
        context.commit('set', data)
    },
    setFullscreen: (context, value) => {
        context.commit('setFullscreen', value)
    },
    setTitle: (context, value) => {
        context.commit('setTitle', value)
    },
    setTopOptionsShown: (context, bool) => {
        context.commit('setTopOptionsShown', bool)
    },

}
const mutations = {
    toggleSidebarDesktop (state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, data) {
        state[data.state] = data.value
    },
    setFullscreen(state, value) {
        state.fullscreen = value
    },
    setTitle(state, value) {
        state.title = value
    },
    setTopOptionsShown: (state, bool) => {
        state.topOptionsShown = bool
    },

}

export default {
    namespaced: namespaced,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
