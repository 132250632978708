<template>
    <div class="modal fade" id="opening-hour-edit-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    Modifier un horaire
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label>Jour de la semaine</label>
                        <v-select v-if="form.day_of_week" :options="weekdays" label="label" :value="weekdays.find(d=> d.id===form.day_of_week)" @input="onSelectDay"></v-select>
                    </div>
                    <div class="form-group" v-if="offices && offices.length > 1 && form.office_id !== null">
                        <label>Lieu</label>
                        <v-select :options="offices" label="name" :value="offices.find(o=> o.id===form.office_id)" @input="onSelectOffice"></v-select>
                    </div>
                    <div class="form-group" v-if="reasons && form.reasons">
                        <label>Motif</label>
                        <v-select multiple :options="reasons" label="label" @input="onSelectReason" :value="form.reasons"></v-select>
                    </div>
                    <div class="form-group">
                        <label>Heure de début</label>
                        <input type="time" class="form-control" v-model="form.opening_time">
                    </div>
                    <div class="form-group">
                        <label>Heure de fin</label>
                        <input type="time" class="form-control" v-model="form.closing_time">
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="onDismiss" class="btn btn-secondary btn-sm"><i class="bi bi-check mr-3"></i>Annuler</button>
                    <button @click.prevent="onSave" class="btn btn-success btn-sm">
                        <span v-if="loading" class="spinner-border spinner-border-sm mr-3" role="status" aria-hidden="true"></span>
                        <span v-if="loading" >Chargement...</span>
                        <span v-if="!loading"><i class="bi bi-check mr-3"></i>Enregistrer
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../../event-bus";
    import EWeekDays from "../../../enums/EWeekDays";
    import axios from 'axios'
    import {mapGetters} from "vuex";
    import {namespaces} from "../../../store/namespaces";
    export default {
        name: "OpeningHourEditModal",
        data() {
            return {
                openingHour: null,
                form: {
                    opening_hour_id: null,
                    doctor_id : null,
                    office_id: null,
                    day_of_week: null,
                    opening_time: null,
                    closing_time: null,
                    reasons: [],
                },
                weekdays: EWeekDays.getWorkingDays(),
                offices: null,
                /*
                    appointment_reasons liés au docteur
                 */
                reasons: null,
                loading: false
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
                getOffices: namespaces.adminCalendar+'getOffices',
            }),
        },
        methods: {
            onDismiss() {
                $('#opening-hour-edit-modal').modal('hide')
            },
            async onSave() {
                this.loading = true
                try {
                    let res = await axios.post('opening-hour/update', this.form, {headers: {
                            "content-type": "application/json",
                            "Accept": "application/json, text/plain",
                            "Authorization": "Bearer " + this.getUser.api_token
                        }})
                    EventBus.$emit('opening-hour-update-confirm', res.data.doctor)
                    $('#opening-hour-edit-modal').modal('hide')
                    Vue.$toast.open({
                        message: 'Horaire modifié avec succès !',
                        type: 'success',
                    });
                }catch(e) {
                    Vue.$toast.open({
                        message: 'Une erreur s\'est produite, veuillez vérifier votre connexion internet.',
                        type: 'error',
                    });
                    console.log(e)
                }
                this.loading = false
            },
            onSelectDay(day) {
                this.form.day_of_week = day.id
            },
            onSelectOffice(office) {
                this.form.office_id = office.id
            },
            onSelectReason(reasons) {
                this.form.reasons = reasons
            }
        },
        mounted() {
            EventBus.$on('opening-hour-edit', (data)=> {
                this.offices = this.getOffices.filter(o=> o.id !== 0)
                if(this.offices.length === 1)this.form.office_id = this.offices[0].id
                this.reasons = data.doctor.reasons
                this.openingHour = data.opening_hour
                this.form.opening_hour_id = data.opening_hour.id
                this.form.doctor_id = data.opening_hour.doctor_id
                this.form.office_id = data.opening_hour.office_id
                this.form.day_of_week = data.opening_hour.day_of_week
                this.form.opening_time = data.opening_hour.opening_time
                this.form.closing_time = data.opening_hour.closing_time
                this.form.reasons = this.reasons.filter(r=> this.openingHour.appointment_reasons.find(ar=> ar.id === r.id))
            })
        }
    }
</script>

<style scoped>

</style>
