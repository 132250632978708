import Vue from 'vue';
export const EventBus = new Vue();

export const EventList = {
    calendar_front: {
        refresh: 'calendar-front-refresh'
    },
    translation: {
        setLocale: 'set-locale'
    }
}