export default class EEventBackgroundColorMode {
    static office = 1
    static reason = 2

    static getList() {
        return [
            {
                label: 'Lieu',
                id: this.office
            },
            {
                label: 'Motif de consultation',
                id: this.reason
            },
        ]
    }
}
