<template>
    <div class="card mb-0 border-info">
        <div class="card-header table-info d-flex justify-content-between">
            <h5 class="modal-title">Impression de rendez-vous</h5>
            <span class="clickable" @click="onDismiss"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <div v-if="appointments && appointments.length>1" class="row">
                <div class="col-md-12">
                    <label class="form-label">{{ $t('calendrier-admin.rdv-repetitifs-trouves') }} :</label>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <div class="btn btn-sm btn-primary mr-3" @click="onSelectAll"><i class="fas fa-mouse-pointer mr-2"></i>{{ $t('calendrier-admin.tout-selectionner') }}</div>
                            <div class="btn btn-sm btn-primary" @click="onSelectNone"><i class="fas fa-times mr-2"></i>{{ $t('calendrier-admin.tout-deselectionner') }}</div>
                        </div>
                    </div>
                    <div  class="row">
                        <template v-for="appointment in appointments">
                            <div class="col-md-4">
                                <label  class="container-checkbox">Le {{appointment.date_debut | displayDate}}
                                    <input type="checkbox" :value="appointment.id" v-model="appointment_ids">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else-if="appointments && appointments.length === 1">
                <div class="row">
                    <div class="col-md-12">
                        Impression de rendez-vous.
                    </div>
                    <template v-for="appointment in appointments">
                        <div class="col-md-4">
                            <label  class="container-checkbox">Le {{appointment.date_debut | displayDate}}
                                <input type="checkbox" :disabled="true" :value="appointment.id" v-model="appointment_ids">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-secondary mr-2" @click="onDismiss">Annuler</button>
            <button :disabled="loading || !appointment_ids || appointment_ids.length === 0" type="button" class="btn btn-sm btn-success mr-2" @click="onPrint">
                <span v-if="printing" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                <span v-if="printing" >Chargement...</span>
                <span v-if="!printing"><i class="fas fa-print mr-2"></i>Imprimer
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import calendarApi from '../../../../../api/calendar'
import moment from 'moment'
export default {
    name: "PrintModal",
    props: {
        appointment: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            printing: false,
            appointments: null,
            appointment_ids: [this.appointment.id]
        }
    },
    computed: {
        ...mapGetters({

        }),
    },
    methods: {
        onDismiss() {
            this.$modal.hide('PrintModal')
        },
        async onPrint() {
            if(this.appointment_ids.length === 0) {
                this.$toast.info('Veuillez sélectionner au moins une date')
                return
            }
            this.printing = true
            try {
                let res = await calendarApi.printMultiple({app_id: Vue.$config.etablissement.id, appointment_ids : this.appointment_ids})
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', moment().format('DD_MM_YYYY_HH_mm_SS')+'.pdf');
                document.body.appendChild(link);
                link.click();
            }catch(e) {
                this.$toast.error('Erreur lors du téléchargement')
                console.log(e)
            }

            this.printing = false
        },
        onSelectAll() {
            this.appointment_ids = []
            this.appointments.forEach(a=> {
                this.appointment_ids.push(a.id)
            })
        },
        onSelectNone() {
            this.appointment_ids = [this.appointment.id]
        }
    },
    async mounted() {
        let res = await calendarApi.getRepeatedAppointments(this.appointment.id)
        this.appointments = res.data.appointments
    }
}
</script>

<style scoped>

</style>
