<template>
    <div class="card mb-0" ref="createModale" id="create-modale">
        <div class="card-header d-flex justify-content-between">
            <h5 class="modal-title">Création d'un patient</h5>
            <span class="clickable" @click="onDismiss"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <div class="form-group row">
                <div class="col-md-6">
                    <label for="name" class="form-control-label">Nom</label>
                    <input type="text" id="name" class="form-control" v-model="form.last_name">
                </div>
                <div class="col-md-6">
                    <label for="firstName" class="form-control-label">Prénom</label>
                    <input type="text" id="firstName" class="form-control" v-model="form.first_name">
                </div>
            </div>

            <div class="form-group row mt-3">
                <div class="col-md-6">
                    <label for="matricule" class="form-control-label">Matricule</label>
                    <input type="text" id="matricule" class="form-control" v-model="form.matricule">
                </div>
                <div class="col-md-6">
                    <label for="email" class="form-control-label">Email</label>
                    <input type="email" id="email" class="form-control" v-model="form.email">
                </div>
            </div>

            <div class="form-group row mt-3">
                <div class="col-md-6">
                    <label for="phone" class="form-control-label">Numéro de téléphone</label>
                    <input type="text" id="phone" class="form-control" v-model="form.cellphone_number">
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <button @click="onDismiss" id="close-create-modal" type="button" class="btn btn-secondary btn-sm mr-2">Annuler</button>
            <button type="button" class="btn btn-primary btn-sm" @click="onConfirmation">
                <i class="bi bi-person-plus-fill mr-1"></i> Créer
            </button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import { namespaces } from "../../../../../store/namespaces"
    import {EventBus} from '../../../../../event-bus'
    import userApi from '../../../../../api/user'

    export default {
        name: "CreateUserModal",
        data() {
            return {
                form: {
                    last_name: null,
                    first_name: null,
                    matricule: null,
                    email: null,
                    cellphone_number: null
                },
            }
        },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
            }),
        },
        methods: {
            async onConfirmation() {
                if (!this.form.last_name || !this.form.first_name) {
                    return Vue.$toast.open({
                        message: 'Veuillez renseigner le nom et le prénom du patient !',
                        type: 'error'
                    })
                }

                if (!this.form.matricule && !this.form.email) {
                    return Vue.$toast.open({
                        message: 'Veuillez renseigner le matricule ou l\'email du patient !',
                        type: 'error'
                    })
                }

                if (this.form.matricule && this.form.matricule.length !== 13) {
                    return Vue.$toast.open({
                        message: 'Matricule invalide !',
                        type: 'error'
                    })
                }

                try {
                    let checkMatricule = null
                    let checkEmail = null

                    if (this.form.matricule)
                        checkMatricule = await userApi.searchTerm(this.form.matricule)

                    if (this.form.email)
                        checkEmail = await userApi.searchTerm(this.form.email)

                    if ((checkMatricule && checkMatricule.data.users && checkMatricule.data.users.length) || (checkEmail && checkEmail.data.users && checkEmail.data.users.length)) {
                        if (checkMatricule && checkMatricule.data.users && checkMatricule.data.users.length) {
                            EventBus.$emit('user-creation', this.form, checkMatricule.data.users[0].id)
                        } else {
                            EventBus.$emit('user-creation', this.form, checkEmail.data.users[0].id)
                        }

                        this.onDismiss()

                        Vue.$toast.open({
                            message: 'Ce matricule ou cet email est déjà existant !',
                            type: 'info'
                        })
                    } else {
                        let res = await userApi.dynamicCreation(this.form)
                        EventBus.$emit('user-creation', res.data.user, res.data.user.id)
                        this.onDismiss()

                        Vue.$toast.open({
                            message: 'Nouveau patient créé avec succès !',
                            type: 'success'
                        })
                    }
                } catch(e) {
                    Vue.$toast.open({
                        message: 'Une erreur s\'est produite, veuillez vérifier votre connexion !',
                        type: 'error'
                    })
                }
            },
            onDismiss() {
                this.$modal.hide('CreateUserModal')
            }
        }
    }
</script>

<style scoped>

</style>
